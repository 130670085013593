const FlatAlert = ({ dismissable=false, message, type='danger' }) => {

    return (
        <div className={`alert alert-${type}-alt alert-dismissible fade show`}>
            {message}
            {dismissable && 
                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            }
        </div>
    );
};

export default FlatAlert;