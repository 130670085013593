import React, { useEffect } from "react";
import { Modal } from 'bootstrap';
import GroupService from "../../Services/GroupsService";
import Helpers from "../../Utils/Helpers";
import Loader from "../../Components/Loader/Loader";
import BtnLoading from "../../Components/BtnLoading/BtnLoading";
import { Form, Formik, Field } from "formik";
import * as Yup from 'yup';
import UserService from "../../Services/UserService";

const UserModal = ({ toedit, askClose }) => {
    const [loading, setLoading] = React.useState(false);
    const [groups, setGroups] = React.useState([]);

    const doGetGroups = () => {
        if (groups.length) {
            return;
        }
        
        setLoading('groups');

        GroupService.getGroups().then(response => {
            setGroups(response.result);
        })
        .catch(err => {
            Helpers.alert.error(err.message);
            askClose();
        })
        .finally(() => setLoading(false));
    };

    const doSaveUser = (values) => {
        setLoading('submit');
        
        const datas = {
            id: toedit.id || null,
            name: values.name,
            email: values.email,
            role: values.role
        };

        UserService.saveUser(datas).then(response => {
            Helpers.alert.success('Utilisateur enregistré');
            askClose(response.user);
        })
        .catch(err => Helpers.alert.error(err.message))
        .finally(() => setLoading(false));
    };

    useEffect(() => {
        const modal = Modal.getOrCreateInstance('#user-modal');
        
        document.querySelector('#user-modal').addEventListener('hidden.bs.modal', () => {
            askClose();
        });

        // load permissions
        toedit && doGetGroups();

        toedit && modal.show();
        !toedit && modal.hide();
    }, [toedit]);

    return (
        <div className="modal fade" id="user-modal" tabIndex="-1">
            <div className="modal-dialog modal-dialog-md modal-dialog-centered">
                <div className="modal-content">
                    {
                        loading === 'groups' && (
                            <div className="position-absolute h-100 w-100 bg-white z-index-3 d-flex justify-content-center align-items-center" style={{zIndex:10}}>
                                <Loader />
                            </div>
                        )
                    }

                    {/*  */}

                    <a href="#" className="modal-close" data-dismiss="modal" aria-label="Close" onClick={() => askClose()}>
                        <em className="ti ti-close"></em>
                    </a>
                    <div className="popup-body">
                        <h2 className="m-0">{toedit?.id ? 'Modifier' : 'Créer'} un utilisateur</h2>
                        <p className="text-gray">{toedit?.id ? 'Modifier un' : 'Créer un nouvel'} utilisateur</p>
                        
                        <Formik
                            enableReinitialize={true}
                            initialValues={{
                                name: toedit?.name || '',
                                email: toedit?.email || '',
                                role: toedit?.role || ''
                            }}
                            validationSchema={
                                Yup.object().shape({
                                    name: Yup.string().required('Le nom du groupe est requis.'),
                                    email: Yup.string().email('Email incorrect.').required('Merci de renseigner votre email.'),
                                    role: Yup.string().required('Vous devez attribuer un role.')
                                })
                            }
                            onSubmit={values => doSaveUser(values)}
                        >
                            {({ errors, touched }) => (
                                <Form>
                                    <div className="input-item input-with-label">
                                        <label className="input-item-label text-exlight">Nom du l'utilisateur</label>
                                        <Field
                                            name="name"
                                            className="input-bordered"
                                            type="text" placeholder="Nom de l'utilisateur"
                                        />
                                        {touched.name && errors.name && <span className="input-note text-danger">Entrez le nom de l'utilisateur</span>}
                                    </div>
                                    <div className="input-item input-with-label">
                                        <label className="input-item-label text-exlight">Email</label>
                                        <Field
                                            name="email"
                                            className="input-bordered"
                                            type="email" placeholder="Email de l'utilisateur"
                                        />
                                        {touched.email && errors.email && <span className="input-note text-danger">Entrez l'email de l'utilisateur</span>}
                                    </div>
                                    <div className="input-item input-with-label">
                                        <label className="input-item-label">Role (groupe)</label>
                                        <div className="select-wrapper">
                                            <Field
                                                as="select"
                                                name="role"
                                                className="input-bordered"
                                            >
                                                <option value={''} disabled>Choisir le role</option>
                                            {
                                                groups.map(item => (
                                                    <option key={item.id.toString()} value={item.code}>{item.name}</option>
                                                ))
                                            }
                                            </Field>
                                        </div>
                                        {touched.role && errors.role && <span className="input-note text-danger">Choisissez le role de l'utilisateur</span>}
                                    </div>
                                    <div className="mt-5">
                                        <button onClick={() => askClose()} type="button" className="btn btn-default btn-dark">Annuler</button>
                                        <BtnLoading
                                            type="submit"
                                            loading={loading === 'submit'}
                                            classes={['btn-primary ml-2']}
                                        >Enregistrer</BtnLoading>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserModal;