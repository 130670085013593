import Fetcher from "../Utils/Fetcher";
import Helpers from "../Utils/Helpers";
import Consts from './Consts';
import FakeStore from "./FakeStore";

const AuthService = {

    getCountries() {
        return Fetcher.get('https://restcountries.com/v3.1/region/africa');
    },

    login(datas) {
        return Fetcher.post(`${Consts.baseapi}/login`, datas);
    },

    resgister(datas) {
        return Fetcher.post(`${Consts.baseapi}/register`, datas);
    },

    forgotPassword(datas) {
        return Fetcher.post(`${Consts.baseapi}/forgot-password`, datas);
    },

    restorePassword(datas) {
        return Fetcher.post(`${Consts.baseapi}/restore-password`, datas);
    },

    updateProfile(datas) {
        return Fetcher.put(`${Consts.baseapi}/user/update`, datas, Helpers.authHeader(FakeStore.token));
    },

    updateProfilePicture(datas) {
        return Fetcher.post(`${Consts.baseapi}/user/picture`, datas, Helpers.authHeader(FakeStore.token), true);
    }

};

export default AuthService;