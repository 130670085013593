import React from 'react';
import Loader from '../../Components/Loader/Loader';
import GroupService from '../../Services/GroupsService';
import Helpers from '../../Utils/Helpers';
import GroupModal from './GroupModal';

const Groups = () => {
    const [groups, setGroups] = React.useState([]);
    const [toEdit, setToEdit] = React.useState(null);
    const [loading, setLoading] = React.useState(false);

    const getGroups = () => {
        setLoading('groups');

        GroupService.getGroups().then(response => {
            setGroups(response.result);
        })
        .catch(err => Helpers.alert.error(err.message))
        .finally(() => setLoading(false));
    };

    const openGroupModal = (toedit) => {
        setToEdit(toedit);
    };

    const closeGroupModal = (datas) => {
        if (datas) {
            if (toEdit.id) {
                const list = groups.map(item => {
                    return item.id === datas.id ? { ...datas } : item;
                });

                setGroups(list);
            }
            else {
                setGroups(state => [...state, datas]);
            }
        }

        openGroupModal(null);

    };

    const toggleGroup = (id) => {
        setLoading('toggling' + id);

        GroupService.toggleGroup(id).then((response) => {
            const list = groups.map(item => (item.id === id ? {...item, active: response.newstate} : item));
            setGroups(list);
        })
        .catch(err => Helpers.alert.error(err.message))
        .finally(() => setLoading(false));
    };

    const removeGroup = (id) => {
        Helpers.alert.confirm('Vous allez supprimer ce groupe. Voulez vous continuer ?').then(() => {
            setLoading('deleting' + id);

            GroupService.deleteGroup(id).then(() => {
                const list = groups.filter(item => item.id !== id);
                setGroups(list);
            })
            .catch(err => Helpers.alert.error(err.message))
            .finally(() => setLoading(false));
        });
    };

    React.useEffect(() => {
        getGroups();
    }, []);

    return (
        <>
        <GroupModal toedit={toEdit} askClose={(datas) => closeGroupModal(datas)} />

        <div className="card">
            <div className="card-innr">
                <div className="card-head">
                    <button onClick={() => openGroupModal({})} className="btn btn-sm btn-primary float-right">Créer un groupe</button>
                    {/*  */}
                    <h4 className="card-title">Liste des groups</h4>
                    <p>Trouvez ci-dessous la liste de vos groupes.</p>
                </div>
            {
                loading !== 'groups' && !!groups.length && (
                    <>
                    <div className="mt-3 table-responsive">
                        <table className="table" style={{borderCollapse: 'separate', borderSpacing: '20px 0px'}}>
                            <thead>
                                <tr>
                                    <th role="th">Nom</th>
                                    <th role="th">Code</th>
                                    <th role="th">Permissions</th>
                                    <th role="th">Status</th>
                                    <th role="th">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                groups.map(item => (
                                    <tr key={item.id.toString()}>
                                        <td>{item.name}</td>
                                        <td>{item.code}</td>
                                        <td>
                                            {
                                                item.permissions ?
                                                    ( item.permissions === '*' ? 'Toutes' : item.permissions.split(',').filter(x => !!x).length)
                                                : 0
                                            } Permission(s)</td>
                                        <td>{parseInt(item.active) === 1 ? 'Activé' : 'Désactivé'}</td>
                                        <td width="120">
                                            <button
                                                style={{width:80}}
                                                onClick={() => toggleGroup(item.id)}
                                                className="btn btn-xs btn-icon btn-dark-alt"
                                                disabled={loading === 'toggling' + item.id}
                                            >
                                                {loading !== ('toggling' + item.id) && <>{ parseInt(item.active) === 1 ? 'Désactiver' : 'Activer' }</>}
                                                {loading === ('toggling' + item.id) && <Loader color="success" width={10} />}
                                            </button>
                                            <button onClick={() => openGroupModal(item)} className="btn btn-xs btn-icon btn-primary-alt ml-1">
                                                <em className="ti ti-pencil"></em>
                                            </button>
                                            <button
                                                onClick={() => removeGroup(item.id)}
                                                className="btn btn-xs btn-icon btn-danger-alt ml-1"
                                                disabled={loading === 'deleting' + item.id}
                                            >
                                                {loading !== ('deleting' + item.id) && <em className="ti ti-trash"></em>}
                                                {loading === ('deleting' + item.id) && <Loader color="dark" width={10} />}
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                    </div>
                    </>
                )
            }
            {
                loading === 'groups' && (
                    <div className="d-flex justify-content-center align-items-center" style={{height: 300}}>
                        <div className="p-4 text-center">
                            <Loader />
                        </div>
                    </div>
                )
            }
            {
                !loading && !groups.length && (
                    <div className="alert alert-primary mt-4">Aucune information disponible.</div>
                )
            }
            </div>
        </div>
        </>
    );
};

export default Groups;