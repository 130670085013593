import Fetcher from "../Utils/Fetcher";
import Helpers from "../Utils/Helpers";
import Consts from './Consts';
import FakeStore from "./FakeStore";

const EntityService = {

    getEntity() {
        return Fetcher.get(`${Consts.baseapi}/shop-entities`, Helpers.authHeader(FakeStore.token));
    },

    updateEntityInfos(datas) {
        return Fetcher.put(`${Consts.baseapi}/shop-entities`, datas, Helpers.authHeader(FakeStore.token));
    },

    updateEntityLogo(datas) {
        return Fetcher.post(`${Consts.baseapi}/shop-entities/update-logo`, datas, Helpers.authHeader(FakeStore.token), true);
    }

};

export default EntityService;