import React, { useState } from "react";
import {useNavigate} from 'react-router-dom';
import BtnLoading from "../../Components/BtnLoading/BtnLoading";
import Loader from "../../Components/Loader/Loader";
import OrdersService from "../../Services/OrdersService";
import ProductService from "../../Services/ProductService";
import Helpers from "../../Utils/Helpers";
import ClientModal from "./ClientModal";

import './Orders.scss';

const NewOrders = () => {
    const prodPerPage = 7;
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);
    const [categories, setCategories] = React.useState([]);
    const [products, setProducts] = React.useState([]);
    const [filteredProducts, setFilteredProducts] = React.useState([]);
    const [cart, setCart] = React.useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [page, setPage] = React.useState(1);
    const [client, setClient] = React.useState(null);
    const [cliModalObj, setCliModalObj] = React.useState(null);


    const loadDefaults = () => {
        setLoading('defaults');

        Promise.all([
            ProductService.getStock(),
            ProductService.getCategories()
        ])
        .then(([stockProducts, categories]) => {
            setProducts(stockProducts.result);
            setCategories(categories.result);

            filterProduct(searchKeyword, selectedCategory, stockProducts.result);
        })
        .catch(err => Helpers.alert.error(err.message))
        .finally(() => setLoading(false));
    };


    const addToCart = (product, qty=1, addOrReplace='ADD') => {
        const found = cart.find(x => x.product_id === product.product_id);

        if (!found) {
            setCart(state => ([...state, {...product, qty}]));
        }
        else {
            const newqty = addOrReplace === 'ADD' ? found.qty + qty : qty;

            if (newqty > (parseFloat(found.quantity||0) - parseFloat(found.sold||0))) {
                return;
            }

            if (!newqty) {
                setCart(state => state.filter(x => x.product_id !== found.product_id));
                return;
            }

            setCart(state => state.map(x => (
                x.product_id === product.product_id ? {...x, qty: newqty} : x
            )));
        }
    };


    const emptyCart = () => {
        Helpers.alert.confirm('Vous supprimer les articles. Souhaitez vous continuer ?').then(() => setCart([]));
    };


    const getProdByPage = (prods, _page) => {
        return prods.slice((_page-1)*prodPerPage, prodPerPage*((_page-1)+1));
    };


    const filterProduct = (keyw, categ, prods=null) => {
        let p = (prods || products).filter(it => (new RegExp(keyw, 'ig')).test(it.product_name));
            p = p.filter(it => it.category_id == categ || categ === '');

        setFilteredProducts(p);
        setPage(1);
    };


    const openCliModal = (toBeEdited) => {
        setCliModalObj(toBeEdited);
    };


    const closeCliModal = (cli) => {
        if (cli) {
            setClient(cli);
        }
        
        setCliModalObj(null);
    };


    const saveOrder = () => {
        Helpers.alert.confirm('Voulez allez créer une commande. Êtes vous sur de vouloir continuer ?').then(() => {
            const values = {
                cart_lines: cart.map(x => ({product: x.product_id, quantity: x.qty})),
                client: client?.id || 0,
                client_address: client?.address || ''
            };
    
            setLoading('saving-order');
    
            OrdersService.createOrder(values).then(response => {
                Helpers.alert.success('Commande bien enregistrée.');
                navigate('/orders');
            })
            .catch(err => Helpers.alert.error(err.message))
            .finally(() => setLoading(false));
        })
    };


    React.useEffect(() => {
        loadDefaults();
    }, []);
    

    return (
        <>
            <ClientModal
                toadd={cliModalObj}
                askClose={closeCliModal}
            />

            <div className="NewOrder">
                <div className="container">
                    <div className="row">
                    {loading !== 'defaults' && (
                        <>
                        <div className="col-md-8">
                            <div className="card bg-light">
                                <div className="card-innr">
                                    <div className="card-header bg-light p-0 pb-3">
                                        <h4 className="font-weight-bold m-0">Créer une nouvelle commande</h4>
                                    </div>
                                    <div className="card-header p-0 py-3 bg-light">
                                        <div className="row">
                                            <div className="col-sm-8">
                                                <div className="relative">
                                                    <label>Nom de l'article</label>
                                                    <span className="input-icon input-icon-left" style={{top:'unset', bottom:-8}}>
                                                        <em className="ti ti-search"></em>
                                                    </span>
                                                    <input
                                                        className="input-bordered"
                                                        type="text" id="search-field"
                                                        onChange={e => {
                                                            filterProduct(e.target.value, selectedCategory);
                                                            setSearchKeyword(e.target.value);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="relative">
                                                    <label>Catégorie</label>
                                                    <span className="input-icon input-icon-left" style={{top:'unset', bottom:-8}}>
                                                        <em className="ti ti-tag"></em>
                                                    </span>
                                                    <select
                                                        className="input-bordered bg-white"
                                                        onChange={e => {
                                                            filterProduct(searchKeyword, e.target.value);
                                                            setSelectedCategory(e.target.value);
                                                        }}
                                                    >
                                                        <option value={''}>Choisir</option>
                                                        {categories.map(it => <option key={it.id} value={it.id}>{it.name}</option>)}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-header p-0 py-3 bg-light">
                                        <div className="row">
                                            <div className="col-12">
                                                <strong>Client: <span className="ml-3 text-primary">{client?.name || 'Inconnu'}</span></strong>
                                                <div className="float-right">
                                                    <button
                                                        className="btn btn-primary btn-xs btn-auto ml-4"
                                                        onClick={() => openCliModal(client || {})}
                                                    >{client ? 'Changer' : 'Ajouter le client'}</button>
                                                    {!!client && (
                                                        <button
                                                            className="btn btn-danger btn-xs btn-auto ml-2"
                                                            onClick={() => setClient(null)}
                                                        >Retirer</button>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table" style={{borderCollapse: 'separate', borderSpacing: '0px 15px'}}>
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th></th>
                                                    <th>Article</th>
                                                    <th>Prix</th>
                                                    <th>Stock</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {getProdByPage(filteredProducts, page).map(item => {
                                                    const remaining = parseFloat(item.quantity||0) - parseFloat(item.sold||0);
                                                    return (
                                                        <React.Fragment key={item.product_id}>
                                                        <tr className="shadow-sm bg-white">
                                                            <td className="pl-2">{item.product_id}</td>
                                                            <td className="px-2" width={50}>
                                                                <img
                                                                    src={item.picture ? Helpers.filePath('Products', item.picture) : 'http://via.placeholder.com/100x100'}
                                                                    className="rounded-circle"
                                                                />
                                                            </td>
                                                            <td>{item.product_name}</td>
                                                            <td>{item.product_sell_price}F</td>
                                                            <td>{remaining}</td>
                                                            <td className="pr-2" width={75}>
                                                                <button
                                                                    className="btn btn-xs btn-success btn-auto px-3"
                                                                    disabled={remaining == 0}
                                                                    onClick={() => addToCart(item)}
                                                                >{remaining == 0 ? 'Epuisé' : 'Ajouter'}</button>
                                                            </td>
                                                        </tr>
                                                        </React.Fragment>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="mt-3 d-flex">
                                        <button
                                            className="btn btn-sm btn-dark btn-auto mr-2"
                                            disabled={page === 1}
                                            onClick={() => setPage(page - 1)}
                                        >Prec</button>
                                        <button
                                            className="btn btn-sm btn-dark btn-auto"
                                            disabled={!getProdByPage(filteredProducts, page + 1).length}
                                            onClick={() => setPage(page + 1)
                                        }>Suiv</button>
                                        <div className="ml-auto">{page} page sur {Math.ceil(filteredProducts.length/prodPerPage)}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card cart-card" style={{position: 'sticky', top: 30}}>
                                <div className="card-innr px-4 d-flex flex-column">
                                    <div className="card-header bg-white p-0 pb-3">
                                        <div className="float-right">
                                            {
                                                !!cart.length && (
                                                    <button className="btn btn-danger btn-xs btn-auto" onClick={() => emptyCart()}>
                                                        <i className="ti ti-trash"></i>
                                                    </button>
                                                )
                                            }
                                        </div>
                                        <h4 className="font-weight-bold m-0">Panier</h4>
                                    </div>
                                    {
                                        !!cart.length && (
                                            <div className="mt-2" style={{maxHeight: 'calc(100vh - 350px)', overflow: 'auto'}}>
                                            {
                                                cart.map((item, idx) => (
                                                <React.Fragment key={idx.toString()}>
                                                    <div className="cart-item">
                                                        <div className="item-wrapper d-flex align-items-center py-3">
                                                            <img
                                                                width={30}
                                                                src={item.picture ? Helpers.filePath('Products', item.picture) : 'http://via.placeholder.com/30x30'}
                                                                className="rounded-circle"
                                                            />
                                                            <div className="mx-3">
                                                                <p className="m-0 font-weight-bold">{item.product_name}</p>
                                                                {/* <span className="text-muted">10000F</span> */}
                                                            </div>
                                                            <div className="ml-auto text-right">{item.product_sell_price} x{item.qty}</div>
                                                        </div>
                                                        <div className="qty-toggle d-flex align-items-center">
                                                            <input
                                                                type="number"
                                                                className="form-control text-center"
                                                                value={item.qty}
                                                                onChange={e => addToCart(item, parseInt(e.target.value), 'REPLACE')}
                                                            />
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                                ))
                                            }
                                            </div>
                                        )
                                    }
                                    {
                                        !cart.length && (
                                            <div className="text-left my-5">
                                                <p className="m-0">Vous n'avez pas</p>
                                                <h2 className="m-0">ajouté d'article à la commande courante</h2>
                                            </div>
                                        )
                                    }
                                    {!!cart.length && (
                                        <>
                                        <div className="border-top pt-3 mt-2">
                                            <h3 className="font-weight-bold">
                                                Total
                                                <span className="float-right">
                                                    {Helpers.formatToAmount(cart.reduce(
                                                        (prev, curr) => (prev + (parseFloat(curr.qty) * parseFloat(curr.product_sell_price)))
                                                    , 0))
                                                    } F
                                                </span>
                                            </h3>
                                        </div>
                                        <div className="border-top pt-3 mt-3">
                                            <BtnLoading
                                                classes={['btn btn-success btn-block']}
                                                loading={loading === 'saving-order'}
                                                onClick={() => saveOrder()}
                                            >Valider le paiement</BtnLoading>
                                        </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        </>
                    )}
                    {loading === 'defaults' && (
                        <div className="col-12">
                            <div className="d-flex justify-content-center align-items-center border bg-white" style={{height: 400}}>
                                <div className="p-4 text-center">
                                    <Loader />
                                </div>
                            </div>
                        </div>
                    )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default NewOrders;
