import React from 'react';
import Loader from '../../Components/Loader/Loader';
import Consts from '../../Services/Consts';
import ProductService from '../../Services/ProductService';
import Helpers from '../../Utils/Helpers';
import CategoryModal from './CategoryModal';
import './Categories.scss';

const Categories = () => {
    const [categories, setCategories] = React.useState([]);
    const [toEdit, setToEdit] = React.useState(null);
    const [loading, setLoading] = React.useState(false);

    const getCategories = () => {
        setLoading('categories');

        ProductService.getCategories().then(response => {
            setCategories(response.result);
        })
        .catch(err => Helpers.alert.error(err.message))
        .finally(() => setLoading(false));
    };

    const openCategoryModal = (toedit) => {
        setToEdit(toedit);
    };

    const toggleCategory = (id) => {
        setLoading('toggling' + id);

        ProductService.toggleCategory(id).then((response) => {
            const list = categories.map(item => (item.id === id ? {...item, active: response.newstate} : item));
            setCategories(list);
        })
        .catch(err => Helpers.alert.error(err.message))
        .finally(() => setLoading(false));
    };

    const removeCategory = (id) => {
        Helpers.alert.confirm('Vous allez supprimer cette catégorie. Voulez vous continuer ?').then(() => {
            setLoading('deleting' + id);

            ProductService.deleteCategory(id).then(() => {
                const list = categories.filter(item => item.id !== id);
                setCategories(list);
            })
            .catch(err => Helpers.alert.error(err.message))
            .finally(() => setLoading(false));
        });
    };

    const closeCategoryModal = (datas) => {
        if (datas) {
            if (toEdit.id) {
                const list = categories.map(item => {
                    return item.id === datas.id ? { ...datas } : item;
                });

                setCategories(list);
            }
            else {
                setCategories(state => [...state, datas]);
            }
        }

        openCategoryModal(null);
    };

    React.useEffect(() => {
        getCategories();
    }, []);

    return (
        <>
        <CategoryModal
            askClose={closeCategoryModal}
            toedit={toEdit}
            categories={categories.filter(item => item.id !== toEdit?.id)}
        />

        <div className="Categories card">
            <div className="card-innr">
                <div className="card-head">
                    <button onClick={() => openCategoryModal({})} className="float-right badge badge-primary badge-lg">Créer une catégorie</button>
                    {/*  */}
                    <h4 className="card-title">Les catégories d'articles</h4>
                    <p>Liste des catégories d'article.</p>
                </div>
            {
                loading !== 'categories' && !!categories.length && (
                    <>
                    <div className="mt-3 table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th role="th">Cover</th>
                                    <th role="th">Nom</th>
                                    <th role="th">Parent</th>
                                    <th role="th">Statut</th>
                                    <th role="th">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                categories.map(item => (
                                    <tr key={item.id.toString()}>
                                        <td>
                                            <div className="list-cover rounded-circle">
                                                <img
                                                    src={item.cover ? Helpers.filePath('Categories', item.cover) : 'http://via.placeholder.com/40x40'}
                                                />
                                            </div>
                                        </td>
                                        <td>{item.name}</td>
                                        <td>{item.parent_name}</td>
                                        <td>{parseInt(item.active) ? 'Activé' : 'Désactivé'}</td>
                                        <td width="120">
                                            <button
                                                style={{width:80}}
                                                onClick={() => toggleCategory(item.id)}
                                                className="btn btn-xs btn-icon btn-dark-alt"
                                                disabled={loading === 'toggling' + item.id}
                                            >
                                                    {loading !== ('toggling' + item.id) && <>{ parseInt(item.active) === 1 ? 'Désactiver' : 'Activer' }</>}
                                                    {loading === ('toggling' + item.id) && <Loader color="success" width={10} />}
                                            </button>
                                            <button onClick={() => openCategoryModal(item)} className="btn btn-xs btn-icon btn-primary-alt ml-1">
                                                <em className="ti ti-pencil"></em>
                                            </button>
                                            <button
                                                onClick={() => removeCategory(item.id)}
                                                className="btn btn-xs btn-icon btn-danger-alt ml-1"
                                                disabled={loading === 'deleting' + item.id}
                                            >
                                                {loading !== ('deleting' + item.id) && <em className="ti ti-trash"></em>}
                                                {loading === ('deleting' + item.id) && <Loader color="dark" width={10} />}
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                    </div>
                    </>
                )
            }
            {
                loading === 'categories' && (
                    <div className="d-flex justify-content-center align-items-center" style={{height: 300}}>
                        <div className="p-4 text-center">
                            <Loader />
                        </div>
                    </div>
                )
            }
            {
                !loading && !categories.length && (
                    <div className="alert alert-primary mt-4">Aucune information disponible.</div>
                )
            }
            </div>
        </div>
        </>
    );
};

export default Categories;