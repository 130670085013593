const Consts = {

    baseapi: 'https://sales.sendem.ci/back/api',
    basefile: 'https://sales.sendem.ci/back/Files',
    baseuri: 'https://sales.sendem.ci/back',
    // baseapi: 'http://192.168.1.18:9000/api',
    // basefile: 'http://192.168.1.18:9000/Files',
    // baseuri: 'http://192.168.1.18:9000',

    statusLabel: {
        CREATED: 'Créée',
        CONFIRMED: 'Confirmée',
        PAID: 'Payée',
        DELIVERING: 'En cours de livraison',
        DELIVERED: 'Livrée',
        CANCELED: 'Annulée'
    }

};

export default Consts;