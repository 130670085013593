import Fetcher from "../Utils/Fetcher";
import Helpers from "../Utils/Helpers";
import Consts from './Consts';
import FakeStore from "./FakeStore";

const UserService = {

    getUsers() {
        return Fetcher.get(`${Consts.baseapi}/users`, Helpers.authHeader(FakeStore.token));
    },

    getUserBy(getby, value, role) {
        return Fetcher.post(`${Consts.baseapi}/users/getby`, {getby, value, role}, Helpers.authHeader(FakeStore.token));
    },

    saveUser(datas) {
        const func = datas.id ? Fetcher.put : Fetcher.post;
        const suffix = datas.id ? '/' + datas.id : '';
        return func(`${Consts.baseapi}/users${suffix}`, datas, Helpers.authHeader(FakeStore.token));
    },

    toggleUser(id) {
        return Fetcher.put(`${Consts.baseapi}/users/${id}/toggle`, {}, Helpers.authHeader(FakeStore.token));
    },

    deleteUser(id) {
        return Fetcher.delete(`${Consts.baseapi}/users/${id}`, Helpers.authHeader(FakeStore.token));
    },

};

export default UserService;