import Fetcher from "../Utils/Fetcher";
import Helpers from "../Utils/Helpers";
import Consts from './Consts';
import FakeStore from "./FakeStore";

const OthersService = {

    getActivities(start, end) {
        const filterString = start || end ? `/filter?start=${start}&end=${end}` : '';

        return Fetcher.get(`${Consts.baseapi}/activities${filterString}`, Helpers.authHeader(FakeStore.token));
    },

    uploadFile(datas, place) {
        return Fetcher.post(`${Consts.baseapi}/files/upload/${place}`, {file: datas}, Helpers.authHeader(FakeStore.token), true);
    }

};

export default OthersService;