import React from "react";
import DebounceInput from 'react-debounce-input';
import { useMatch, useNavigate } from "react-router-dom";
import Loader from "../../Components/Loader/Loader";
import ProductService from "../../Services/ProductService";
import Helpers from "../../Utils/Helpers";
import ProductModal from "./ProductModal";
import './Products.scss';

const Products = () => {
    const [products, setProducts] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [toEdit, setToEdit] = React.useState(false);
    const [categories, setCategories] = React.useState([]);
    const [searchKeyword, setSearchKeyword] = React.useState('');
    const [selectedCategory, setSelectedCategory] = React.useState(0);
    const [page, setPage] = React.useState(1);
    const [length, setLength] = React.useState(0);
    const matchAdd = useMatch('/products/add');
    const navigate = useNavigate();

    const getProducts = (filters) => {
        setLoading('products');

        ProductService.getProducts(filters)
        .then(response => {
            setProducts(response.result);
            setLength(response.plength);
        })
        .catch(err => Helpers.alert.error(err.message))
        .finally(() => setLoading(false));
    };
    
    const toggleProduct = (id) => {
        setLoading('toggling' + id);

        ProductService.toggleProduct(id).then((response) => {
            const list = products.map(item => (item.id === id ? {...item, active: response.newstate} : item));
            setProducts(list);
        })
        .catch(err => Helpers.alert.error(err.message))
        .finally(() => setLoading(false));
    };

    const removeProduct = (id) => {
        Helpers.alert.confirm('Vous allez supprimer cet article. Voulez vous continuer ?').then(() => {
            setLoading('deleting' + id);

            ProductService.deleteProduct(id).then(() => {
                const list = products.filter(item => item.id !== id);
                setProducts(list);
            })
            .catch(err => Helpers.alert.error(err.message))
            .finally(() => setLoading(false));
        });
    };

    const openProductModal = (toedit) => {
        setToEdit(toedit);
    };

    const closeProductModal = (datas) => {
        if (datas) {
            if (toEdit.id) {
                const list = products.map(item => {
                    return item.id === datas.id ? { ...datas } : item;
                });

                setProducts(list);
            }
            else {
                setProducts(state => [...state, datas]);
            }
        }

        openProductModal(null);
    };

    const doFilter = ({selectedCategory, searchKeyword, page}) => {
        getProducts(Helpers.objToUrlParams({
            cat: selectedCategory || null,
            srk: searchKeyword || null,
            pge: page <= 1 ? null : page
        }, true));
    };

    React.useEffect(() => {
        doFilter({selectedCategory, searchKeyword, page});
        
        // if come with add
        if (matchAdd) {
            openProductModal({});
            navigate('/products', { replace: true });
        }
    }, [searchKeyword, selectedCategory, page]);

    return (
        <>
        <ProductModal
            toedit={toEdit}
            askClose={datas => closeProductModal(datas)}
            categories={categories}
            setCategories={categs => setCategories(categs)}
        />

        <div className="Produtcs">
            <div className="card">
                <div className="card-innr">
                    <div className="card-head">
                        <div className="float-right d-flex">
                            <button
                                onClick={() => openProductModal({})}
                                className="badge badge-primary badge-lg"
                            >Ajouter un article</button>
                        </div>
                        {/*  */}
                        <h4 className="card-title">Les articles</h4>
                        <p>Liste des articles de votre boutique.</p>
                    </div>
                    <div className="card-head d-flex align-items-center justify-content-between border-top border-bottom py-2">
                        <h4 className="font-weight-bold m-0">Filtrer</h4>
                        <div className="d-flex py-1">
                            <DebounceInput
                                minLength={2}
                                debounceTimeout={1000}
                                type="text"
                                className="input-bordered mr-1"
                                placeholder="Rechercher dans les articles"
                                onChange={event => setSearchKeyword(event.target.value)}
                            />
                            <select className="input-bordered"
                                value={selectedCategory}
                                onChange={evt => setSelectedCategory(evt.target.value)}
                            >
                                <option value={0}>Choisir la catégorie</option>
                                {categories.map(item => <option value={item.id} key={item.id.toString()}>{item.name}</option>)}
                            </select>
                        </div>
                    </div>
                {
                    loading !== 'products' && !!products.length && (

                        <div className="">
                            <div className="table-responsive">
                                <table className="table" style={{borderCollapse: 'separate', borderSpacing: '10px 20px'}}>
                                    <thead>
                                        <tr>
                                            <th role="th">Photo</th>
                                            <th role="th">Article</th>
                                            <th role="th" className="text-center">Prix (TTC)</th>
                                            <th role="th">Categorie</th>
                                            <th role="th">Status</th>
                                            <th role="th">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        products.map(item => (
                                            <React.Fragment key={item.id.toString()}>
                                                <tr className="rounded shadow-sm">
                                                    <td className="px-2" width={70}>
                                                        <img
                                                            src={item.picture ? Helpers.filePath('Products', item.picture) : 'http://via.placeholder.com/100x100'}
                                                            className="rounded-circle"
                                                            width={40}
                                                        />
                                                    </td>
                                                    <td className="px-2">{item.name}</td>
                                                    <td className="text-center px-2">{parseFloat(item.price) ? `${item.price}F CFA` : 'Pas de stock'}</td>
                                                    <td className="px-2">{item.category_name || '--'}</td>
                                                    <td className="text-center">{item.active ? 'Activé' : 'Désactivé'}</td>
                                                    <td className="px-2" width={100}>
                                                        <button
                                                            onClick={() => toggleProduct(item.id)}                                               
                                                            style={{minWidth:30, minHeight:30, padding:0}}
                                                            className="badge badge-outline badge-sm badge-dark rounded"
                                                        >
                                                            {
                                                                loading !== ('toggling' + item.id) && (
                                                                    <>{ parseInt(item.active) === 1 ?
                                                                        <em className="ti ti-eye"></em> :
                                                                        <em className="bx bxs-hide"></em>
                                                                    }</>
                                                                )
                                                            }
                                                            {loading === ('toggling' + item.id) && <Loader color="dark" width={10} />}
                                                        </button>
                                                        <button
                                                            style={{minWidth:30, minHeight:30, padding:0}}
                                                            className="badge badge-outline badge-sm badge-primary rounded ml-1"
                                                            onClick={() => openProductModal(item)}
                                                        >
                                                            <em className="ti ti-pencil"></em>
                                                        </button>
                                                        <button
                                                            onClick={() => removeProduct(item.id)}
                                                            style={{minWidth:30, minHeight:30, padding:0}}
                                                            className="badge badge-outline badge-sm badge-danger rounded ml-1"
                                                        >
                                                            {loading !== ('deleting' + item.id) && <em className="ti ti-trash"></em>}
                                                            {loading === ('deleting' + item.id) && <Loader color="danger" width={10} />}
                                                        </button>
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        ))
                                    }
                                    </tbody>
                                </table>
                            </div>
                            <div className="mt-3 border-top pt-3">
                                <button
                                    onClick={() => setPage(page - 1)}
                                    disabled={page <= 1}
                                    className="badge badge-primary badge-sm"
                                >Précédent</button>
                                <button
                                    onClick={() => setPage(page + 1)}
                                    disabled={length > products.length}
                                    className="badge badge-primary badge-sm ml-2"
                                >Suivant</button>
                            </div>
                        </div>
                    )
                }
                {
                loading === 'products' && (
                    <div className="d-flex justify-content-center align-items-center" style={{height: 300}}>
                        <div className="p-4 text-center">
                            <Loader />
                        </div>
                    </div>
                )
                }
                {
                    !loading && !products.length && (
                        <div className="alert alert-primary mt-4">Aucune information disponible.</div>
                    )
                }
                </div>
            </div>
        </div>
        </>
    );
};

export default Products;