import React from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import BtnLoading from '../../Components/BtnLoading/BtnLoading';
import AuthService from '../../Services/AuthService';
import FlatAlert from '../../Components/FlatAlert/FlatAlert';

export default function Signup() {
    const params = useParams();
    const [countries, setCountries] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [finish, setFinish] = React.useState(false);
    const formik = useFormik({
        initialValues: {
            shop_name: '',
            country: 225,
            admin_name: '',
            admin_email: '',
            admin_password: '',
            admin_confirm_password: ''
        },
        validationSchema: Yup.object().shape({
            shop_name: Yup.string().required('Entrez le nom de la boutique').min(3, 'Nom de la boutique invalide'),
            country: Yup.number().min(1, 'Choisir le pays'),
            admin_name: Yup.string().required('Veuillez entrer votre nom'),
            admin_email: Yup.string().email('Entrez un email correct').required('Veuillez entrer votre email'),
            admin_password: Yup.string().required('Choisir un mot de passe').min(6, 'Mot de passe court'),
            admin_confirm_password: Yup.string().oneOf([Yup.ref('admin_password'), null], 'Les mots de passe ne correspondent pas')
        }),
        onSubmit(values) {
            doRegister(values);
        }
    });

    const doRegister = (values) => {
        setError(null);
        setLoading(true);

        AuthService.resgister(values).then(result => {
            setFinish(true);
        })
        .catch(err => {
            setError(err.message);
        })
        .finally(() => {
            setLoading(false);
        });
    };

    React.useEffect(() => {
        // get countries
        AuthService.getCountries().then(result => {
            setCountries(result);
        });
    }, []);

    return (
        <>
        {
            !params.confirm && !finish && (
                <>
                <h2 className="page-ath-heading">Créez votre compte entreprise <small>Pour gerer votre boutique en ligne</small></h2>

                {/*  */}

                {error && <FlatAlert message={error} />}

                <form onSubmit={formik.handleSubmit} className="form-validate validate-modern">
                    <div className="input-item">
                        <input
                            name="shop_name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.shop_name}
                            type="text"
                            placeholder="Votre entreprise"
                            className="input-bordered error"
                        />
                        {formik.touched.shop_name && formik.errors.shop_name && 
                            <label id="full-name-error" className="error">{formik.errors.shop_name}</label>}
                    </div>
                    <div className="input-item pb-0">
                        <div className="select-wrapper">
                            <select
                                name="country"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.country}
                                className="input-bordered"
                            >
                                <option value={0}>Choisir le pays</option>
                                {countries.map((item, idx) => (
                                    <option key={idx} value={parseInt(item.idd.root+item.idd.suffixes[0].replace('+',''))}>{item.translations.fra.common}</option>
                                ))}
                            </select>
                            {formik.touched.country && formik.errors.country && 
                            <label id="full-name-error" className="error">{formik.errors.country}</label>}
                        </div>
                    </div>
                    <div className="sap-text">
                        <span>Administrateur</span>
                    </div>
                    <div className="input-item">
                        <input
                            name="admin_name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.admin_name}
                            type="text"
                            placeholder="Votre nom"
                            className="input-bordered"
                        />
                        {formik.touched.admin_name && formik.errors.admin_name && 
                            <label id="full-name-error" className="error">{formik.errors.admin_name}</label>}
                    </div>
                    <div className="input-item">
                        <input
                            name="admin_email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.admin_email}
                            type="email"
                            placeholder="Votre email"
                            className="input-bordered"
                        />
                        {formik.touched.admin_email && formik.errors.admin_email && 
                            <label id="full-name-error" className="error">{formik.errors.admin_email}</label>}
                    </div>
                    <div className="input-item">
                        <input
                            name="admin_password"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.admin_password}
                            type="password"
                            placeholder="Votre mot de passe"
                            className="input-bordered"
                        />
                        {formik.touched.admin_password && formik.errors.admin_password && 
                            <label id="full-name-error" className="error">{formik.errors.admin_password}</label>}
                    </div>
                    <div className="input-item">
                        <input
                            name="admin_confirm_password"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.admin_confirm_password}
                            type="password"
                            placeholder="Votre mot de passe"
                            className="input-bordered"
                        />
                        {formik.touched.admin_confirm_password && formik.errors.admin_confirm_password && 
                            <label id="full-name-error" className="error">{formik.errors.admin_confirm_password}</label>}
                    </div>
                    <div className="row">
                        <div className="col-5 mb-2">
                            <Link to="/auth" className="btn btn-default btn-block text-dark">Annuler</Link>
                        </div>
                        <div className="col-7 mb-2">
                            <BtnLoading type="submit" classes={['btn-primary', 'btn-block']} loading={loading}>S'inscrire</BtnLoading>
                        </div>
                    </div>
                </form>
                </>
            )
        }
        {
            !params.confirm && finish && (
                <>
                <div class="status status-thank p-0">
                    <div class="status-icon m-0">
                        <em class="ti ti-check"></em>
                    </div>
                </div>
                <h2 className="page-ath-heading mt-4">
                    Félicitations !
                    <small>Votre compte a été créé. Un email de confirmation vous été envoyé à <b>{formik.values.admin_email}</b>.</small>
                </h2>

                <Link to="/auth" className="btn btn-primary">Retourner à l'accueil</Link>
                </>
            )
        }
        {
            params.confirm && params.confirm === 'confirm-failed' && (
                <>
                <h2 className="page-ath-heading mt-4 text-danger">
                    Echec
                    <small>Nous n'avons pas pu activer votre compte. Veuillez réessayer ou nous contacter.</small>
                </h2>

                <Link to="/auth" className="btn btn-primary">Retourner à l'accueil</Link>
                </>
            )
        }
        {
            params.confirm && params.confirm === 'confirm-done' && (
                <>
                <div class="status status-thank p-0">
                    <div class="status-icon m-0">
                        <em class="ti ti-check"></em>
                    </div>
                </div>
                <h2 className="page-ath-heading mt-4">
                    Boutique activée !
                    <small>Votre compte est maintenant validé. Vous pouvez à présent vous connecter.</small>
                </h2>

                <Link to="/auth" className="btn btn-primary">Me connecter</Link>
                </>
            )
        }
        {
            params.confirm && ['confirm-done', 'confirm-failed'].indexOf(params.confirm) === -1 && (
                <Navigate to="/auth" replace={true} />
            )
        }
        </>
    );
};