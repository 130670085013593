import { LOGIN_ACTION, LOGOUT_ACTION, UPDATE_ACTION } from "../Actions/UserActions";

export function UserReducer(state = null, action) {
    
    switch (action.type) {
        case LOGIN_ACTION:
            return action.payload;

        case UPDATE_ACTION:
            return {...state, ...action.payload};

        case LOGOUT_ACTION:
            return null;
            
        default:
            return state;
    }
}