import { useFormik } from "formik";
import React from "react";
import { connect } from "react-redux";
import * as Yup from 'yup';
import BsSpinner from "../../Components/BsSpinner/BsSpinner";
import BtnLoading from "../../Components/BtnLoading/BtnLoading";
import AuthService from "../../Services/AuthService";
import Consts from "../../Services/Consts";
import FakeStore from "../../Services/FakeStore";
import { userUpdate } from "../../Store/Actions/UserActions";
import Helpers from "../../Utils/Helpers";
import './Profile.scss';

const Profile = ({ user, dispatch }) => {
    const [loading, setLoading] = React.useState(false);

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            address: '',
            phone: '',
            password: '',
            cpassword: ''
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required('Entrez votre nom.').min(2, 'Nom pas correct.'),
            email: Yup.string().required('Entrez votre email').email('Entrez un email correct.'),
            password: Yup.string().min(6, 'Mot de passe trop court.'),
            cpassword: Yup.string().oneOf([Yup.ref('password')], 'Les mots de passe ne correspondent pas.')
        }),
        onSubmit(values) {
            doUpdateProfile(values);
        }
    });

    const setInfos = (infos) => {
        formik.setFieldValue('name', infos?.name);
        formik.setFieldValue('email', infos?.email);
        formik.setFieldValue('address', infos?.address);
        formik.setFieldValue('phone', infos?.phone);
        formik.setFieldValue('password', '');
        formik.setFieldValue('cpassword', '');
    };

    const updateStoreUser = (infos) => {
        const updated = {
            ...user,
            infos: {
                ...user.infos,
                name: infos.name,
                email: infos.email,
                phone: infos.phone,
                address: infos.address,
                profile_picture: infos.profile_picture || user.infos.profile_picture
            }
        };

        dispatch(userUpdate(updated));
    };

    const doUpdateProfile = (values) => {
        setLoading('INFOS');

        AuthService.updateProfile(values).then(() => {
            updateStoreUser(values);
            Helpers.alert.success('Vos informations ont bien été mises à jour.');
        })
        .catch(err => Helpers.alert.error(err.message))
        .finally(() => setLoading(false));
    };

    const doProfilePictureChange = (evt) => {
        setLoading('PROFILE_PICTURE');

        AuthService.updateProfilePicture({file: evt.target.files[0]})
        .then(result => {
            dispatch(userUpdate({...user?.infos, profile_picture: result.picture}));
            Helpers.alert.success('Photo de profil mise à jour.');
        })
        .catch(err => Helpers.alert.error(err.message))
        .finally(() => setLoading(false));
    };

    React.useEffect(() => {
        setInfos(user?.infos);
    }, []);

    return (
        <div className="container ProfilePage">
            <div className="row">
                <div className="col-md-9">
                    <div className="card">
                        <div className="card-innr">
                            <div className="card-head">
                                <h4 className="card-title">Détails du profil</h4>
                                <p>Modifiez les informations de votre profil.</p>
                            </div>
                            <form onSubmit={formik.handleSubmit}>
                                <div className="row">
                                    <div className="col-md-12 mb-2">
                                        <div className="input-item input-with-label">
                                            <label className="input-item-label">Nom et prénoms</label>
                                            <input
                                                name="name"
                                                className="input-bordered"
                                                type="text"
                                                placeholder="Votre nom"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.name}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <div className="input-item input-with-label">
                                            <label className="input-item-label">E-mail</label>
                                            <input
                                                name="email"
                                                className="input-bordered"
                                                type="text"
                                                placeholder="Votre email"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.email}
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <div className="input-item input-with-label">
                                            <label className="input-item-label">Téléphone</label>
                                            <input
                                                name="phone"
                                                className="input-bordered"
                                                type="text"
                                                placeholder="Votre téléphone"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.phone}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-2">
                                        <div className="input-item input-with-label">
                                            <label className="input-item-label">Adresse</label>
                                            <textarea
                                                name="address"
                                                className="input-bordered"
                                                type="text"
                                                placeholder="Votre adresse"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.address}
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <div className="row mt-4">
                                    <div className="col-12 mb-3">
                                        <h6 className="card-title">Modification du mot de passe</h6>
                                        <p>Traitez cette partie avec délicatesse</p>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <div className="input-item input-with-label">
                                            <label className="input-item-label">Nouveau mot de passe</label>
                                            <input
                                                name="password"
                                                className="input-bordered"
                                                type="password"
                                                placeholder="Votre nouveau mot de passe"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.password}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <div className="input-item input-with-label">
                                            <label className="input-item-label">Confirmez le mot de passe</label>
                                            <input
                                                name="cpassword"
                                                className="input-bordered"
                                                type="password"
                                                placeholder="Confirmer le de passe"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.cpassword}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <BtnLoading
                                    type="submit"
                                    classes={['btn-primary']}
                                    loading={loading === 'INFOS'}
                                >Enregistrer</BtnLoading>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="content-area card">
                        <div className="card-innr">
                            <div className="profile-picture">
                                <img
                                    alt=""
                                    src={
                                        user?.infos?.profile_picture ?
                                            Helpers.filePath('Profile', user.infos.profile_picture) :
                                            'http://via.placeholder.com/200x200'}
                                    />
                            </div>
                                
                            <label htmlFor="profile_picture" className="btn btn-primary btn-block mt-3">
                                <input type="file" id="profile_picture" className="d-none" onChange={doProfilePictureChange} />
                                {loading !== 'PROFILE_PICTURE' && <><i className="ti ti-camera"></i> &nbsp; Photo de profile</>}
                                {loading === 'PROFILE_PICTURE' && <BsSpinner type="white" size="sm" />}
                            </label>
                        </div>
                    </div>
                    <div className="content-area card">
                        <div className="card-innr">
                            <div className="card-head">
                                {/* <h4 className="card-title">Resumé des infos</h4> */}
                                {/* <p>A propos de vous</p> */}
                            </div>
                            <div className="">
                                <h6 className="card-title card-title-sm">Nom & prénoms</h6>
                                <p>{user?.infos?.name}</p>
                            </div>
                            <div className="mt-3">
                                <h6 className="card-title card-title-sm">Adresse email</h6>
                                <p>{user?.infos?.email}</p>
                            </div>
                            <div className="mt-3">
                                <h6 className="card-title card-title-sm">Adresse</h6>
                                <p>{user?.infos?.address || 'Non précisé'}</p>
                            </div>
                            <div className="mt-3">
                                <h6 className="card-title card-title-sm">Téléphone</h6>
                                <p>{user?.infos?.phone || 'Non précisé'}</p>
                            </div>
                            <div className="mt-3">
                                <h6 className="card-title card-title-sm">Role</h6>
                                <p>{user?.infos?.role || 'Non précisé'}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    user: state.user
});

export default connect(mapStateToProps)(Profile);