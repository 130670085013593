import React from "react";
import {Link, Outlet} from 'react-router-dom';

const UserLayout = () => {

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-lg-3">
                        <div className="card">
                            <div className="card-innr">
                                <div className="card-head">
                                    <h4 className="card-title">Administration</h4>
                                    <ul className="sidebar-nav mt-3">
                                        <li><Link to="/users"><em className="ikon bx bxs-user-badge"></em> Utilisateurs</Link></li>
                                        <li><Link to="/users/groups"><em className="ikon bx bxs-group"></em> Groupes</Link></li>
                                        <li><Link to="/users/logs"><em className="ikon bx bxs-spreadsheet"></em> Activités</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-9">

                        <Outlet />
                        
                    </div>
                </div>
            </div>
        </>
    );
};

export default UserLayout;