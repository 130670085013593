import React from 'react';
import { Modal } from 'bootstrap';
import { Form, Formik, Field } from "formik";
import * as Yup from 'yup';
import ProductService from "../../Services/ProductService";
import Helpers from "../../Utils/Helpers";
import BtnLoading from "../../Components/BtnLoading/BtnLoading";

const StocksModal = ({ toadd, askClose }) => {
    const [loading, setLoading] = React.useState(false);

    const actions = [
        {code: 'REM', label: 'Retirer du stock'},
        {code: 'ADD', label: 'Ajouter au stock'},
    ];

    const doSaveStock = (values) => {
        setLoading('submit');
        
        const datas = {
            product: toadd.product_id,
            action: values.action,
            quantity: values.quantity,
            product_buy_price: values.product_buy_price,
            product_sell_price: values.product_sell_price
        };

        ProductService.saveStock(datas).then(response => {
            Helpers.alert.success('Stock mis à jour.');
            askClose(response);
        })
        .catch(err => Helpers.alert.error(err.message))
        .finally(() => setLoading(false));
    };

    React.useEffect(() => {
        const modal = Modal.getOrCreateInstance('#stock-modal', {backdrop: 'static', keyboard: false});

        toadd && modal.show();
        !toadd && modal.hide();
    }, [toadd]);

    return (
        <div className="modal fade" id="stock-modal" tabIndex="-1">
            <div className="modal-dialog modal-dialog-lg modal-dialog-centered">
                <div className="modal-content">
                    <a href="#" className="modal-close" data-dismiss="modal" aria-label="Close" onClick={() => askClose()}>
                        <em className="ti ti-close"></em>
                    </a>
                    <div className="popup-body">
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="m-0">Stock</h2>
                                <p className="text-gray">Ajouter des articles au stock.</p>
                                
                                <Formik
                                    enableReinitialize={true}
                                    initialValues={{
                                        product_name: toadd?.product_name || '',
                                        product_buy_price: 0,
                                        product_sell_price: 0,
                                        action: 'ADD',
                                        quantity: 0,
                                    }}
                                    validationSchema={
                                        Yup.object().shape({
                                            product_name: Yup.string().required('Le nom de l\'article est requise.'),
                                            action: Yup.string().required('L\' action est requise.'),
                                            product_buy_price: Yup.number().min(1, 'Le prix unitaire est requis.'),
                                            product_sell_price: Yup.number().min(1, 'Le prix unitaire de vente est requis.'),
                                            quantity: Yup.number().required('La est requise.')
                                        })
                                    }
                                    onSubmit={values => doSaveStock(values)}
                                >
                                    {({ errors, touched }) => (
                                        <Form>
                                            <div className="input-item input-with-label">
                                                <label className="input-item-label text-exlight">Nom de l'article</label>
                                                <Field
                                                    name="product_name"
                                                    className="input-bordered"
                                                    disabled={true}
                                                    type="text" placeholder="Nom de l'article"
                                                />
                                            </div>
                                            <div className="input-item input-with-label">
                                                <label className="input-item-label">Action</label>
                                                <div className="select-wrapper">
                                                    <Field
                                                        as="select"
                                                        name="action"
                                                        className="input-bordered"
                                                        disabled={true}
                                                    >
                                                        <option value={0} disabled>Choisir une action</option>
                                                    {
                                                        actions.map(item => (
                                                            <option key={item.code.toString()} value={item.code}>{item.label}</option>
                                                        ))
                                                    }
                                                    </Field>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="input-item input-with-label">
                                                        <label className="input-item-label text-exlight">Prix unitaire</label>
                                                        <Field
                                                            name="product_buy_price"
                                                            className="input-bordered"
                                                            type="number" placeholder="Prix unitaire d'achat"
                                                        />
                                                        {touched.product_buy_price && errors.product_buy_price && <span className="input-note text-danger">{errors.product_buy_price}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-item input-with-label">
                                                        <label className="input-item-label text-exlight">Prix de vente</label>
                                                        <Field
                                                            name="product_sell_price"
                                                            className="input-bordered"
                                                            type="number" placeholder="Prix unitaire de vente"
                                                        />
                                                        {touched.product_sell_price && errors.product_sell_price && <span className="input-note text-danger">{errors.product_sell_price}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="input-item input-with-label">
                                                <label className="input-item-label text-exlight">Quantité</label>
                                                <Field
                                                    name="quantity"
                                                    className="input-bordered"
                                                    type="number" placeholder="Quantité"
                                                />
                                                {touched.quantity && errors.quantity && <span className="input-note text-danger">{errors.quantity}</span>}
                                            </div>
                                            <div className="mt-5">
                                                <button onClick={() => askClose()} type="button" className="btn btn-default btn-dark">Annuler</button>
                                                <BtnLoading
                                                    type="submit"
                                                    loading={loading === 'submit'}
                                                    classes={['btn-primary ml-2']}
                                                >Enregistrer</BtnLoading>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StocksModal;