import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Loader from '../../Components/Loader/Loader';
import Consts from '../../Services/Consts';
import UserService from '../../Services/UserService';
import Helpers from '../../Utils/Helpers';
import UserModal from './UserModal';

const Users = ({ user }) => {
    const [users, setUsers] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [toEdit, setToEdit] = React.useState(null);

    const getUsers = () => {
        setLoading('users');

        UserService.getUsers().then(response => {
            setUsers(response.result);
        })
        .catch(err => Helpers.alert.error(err.message))
        .finally(() => setLoading(false));
    };
    
    const toggleUser = (id) => {
        setLoading('toggling' + id);

        UserService.toggleUser(id).then((response) => {
            const list = users.map(item => (item.id === id ? {...item, active: response.newstate} : item));
            setUsers(list);
        })
        .catch(err => Helpers.alert.error(err.message))
        .finally(() => setLoading(false));
    };

    const removeUser = (id) => {
        Helpers.alert.confirm('Vous allez supprimer cet utilisateur. Voulez vous continuer ?').then(() => {
            setLoading('deleting' + id);

            UserService.deleteUser(id).then(() => {
                const list = users.filter(item => item.id !== id);
                setUsers(list);
            })
            .catch(err => Helpers.alert.error(err.message))
            .finally(() => setLoading(false));
        });
    };

    const openUserModal = (toedit) => {
        setToEdit(toedit);
    };

    const closeUserModal = (datas) => {
        if (datas) {
            if (toEdit.id) {
                const list = users.map(item => {
                    return item.id === datas.id ? { ...datas } : item;
                });

                setUsers(list);
            }
            else {
                setUsers(state => [...state, datas]);
            }
        }

        openUserModal(null);
    };

    useEffect(() => {
        getUsers();
    }, []);

    return (
        <>
            <UserModal toedit={toEdit} askClose={(datas) => closeUserModal(datas)} />

            <div className="card">
                <div className="card-innr">
                    <div className="card-head">
                        <button onClick={() => openUserModal({})} className="btn btn-sm btn-primary float-right">Créer un utilisateur</button>
                        {/*  */}
                        <h4 className="card-title">Liste des utilisateurs</h4>
                        <p>Trouvez ci-dessous la liste des utilisateurs.</p>
                    </div>
                {
                    loading !== 'users' && !!users.length && (
                        <>
                        <div className="mt-3 table-responsive">
                            <table className="table" style={{borderCollapse: 'separate', borderSpacing: '10px 10px'}}>
                                <thead>
                                    <tr>
                                        <th role="th">Photo</th>
                                        <th role="th">Nom</th>
                                        <th role="th">Email</th>
                                        {/* <th role="th">Téléphone</th> */}
                                        <th role="th">Groupe</th>
                                        <th role="th">Status</th>
                                        <th role="th">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    users.map(item => (
                                        <tr key={item.id.toString()} className="rounded shadow-sm">
                                            <td className="pl-2">
                                                <img
                                                    width={35}
                                                    src={item.profile_picture ? Consts.basefile + '/Profile/' + item.profile_picture : 'http://via.placeholder.com/40x40'}
                                                    className="rounded-circle"
                                                />
                                            </td>
                                            <td>{ item.name }</td>
                                            <td>{ item.email }</td>
                                            {/* <td>{ item.phone }</td> */}
                                            <td>{ item.role_label }</td>
                                            <td>{ parseInt(item.active) === 1 ? 'Activé' : 'Désactivé' }</td>
                                            <td width="120" className="pr-2">
                                            {
                                                user?.infos?.id !== item.id && (
                                                    <>
                                                    <button
                                                        style={{width:80}}
                                                        onClick={() => toggleUser(item.id)}
                                                        className="btn btn-xs btn-icon btn-dark-alt"
                                                        disabled={loading === 'toggling' + item.id}
                                                    >
                                                            {loading !== ('toggling' + item.id) && <>{ parseInt(item.active) === 1 ? 'Désactiver' : 'Activer' }</>}
                                                            {loading === ('toggling' + item.id) && <Loader color="success" width={10} />}
                                                    </button>
                                                    <button onClick={() => openUserModal(item)} className="btn btn-xs btn-icon btn-primary-alt ml-1">
                                                        <em className="ti ti-pencil"></em>
                                                    </button>
                                                    <button
                                                        onClick={() => removeUser(item.id)}
                                                        className="btn btn-xs btn-icon btn-danger-alt ml-1"
                                                        disabled={loading === 'deleting' + item.id}
                                                    >
                                                        {loading !== ('deleting' + item.id) && <em className="ti ti-trash"></em>}
                                                        {loading === ('deleting' + item.id) && <Loader color="dark" width={10} />}
                                                    </button>
                                                    </>
                                                )
                                            }
                                            </td>
                                        </tr>
                                    ))
                                }   
                                </tbody>
                            </table>
                        </div>
                        </>
                    )
                }
                {
                    loading === 'users' && (
                        <div className="d-flex justify-content-center align-items-center" style={{height: 300}}>
                            <div className="p-4 text-center">
                                <Loader />
                            </div>
                        </div>
                    )
                }
                {
                !loading && !users.length && (
                    <div className="alert alert-primary mt-4">Aucune information disponible.</div>
                    )
                }
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    user: state.user
});

export default connect(mapStateToProps)(Users);