import swal from 'sweetalert';
import moment from 'moment';
import Consts from '../Services/Consts';

const Helpers = {

    authHeader(token, others) {
        return {Authorization: 'Bearer ' + token, ...(others || {})};
    },

    alert: {
        tell(type, title, message) {
            return new Promise((resolve, reject) => {
                swal({
                    title: title,
                    text: message,
                    icon: type
                }).then(() => {
                    resolve();
                });
            });
        },

        error(message) {
            return Helpers.alert.tell('error', 'Erreur', message);
        },

        success(message) {
            return Helpers.alert.tell('success', 'Succès', message);
        },

        confirm(message) {
            return new Promise((resolve, reject) => {
                swal({
                    title: 'Confirmez !',
                    text: message,
                    icon: 'warning',
                    buttons: {
                        cancel: 'Annuler',
                        confirm: {
                            text: 'Je confirme',
                            value: true
                        }
                    }
                }).then((result) => {
                    if (result) {
                        resolve();
                    }
                    else {
                        reject();
                    }
                });
            });
        }
    },

    parseDate(datestring) {
        const y = datestring.substring(0, 4);
        const m = datestring.substring(4, 6);
        const d = datestring.substring(6, 8);
        const h = datestring.substring(8, 10) || '00';
        const i = datestring.substring(10, 12) || '00';

        return moment(`${y}-${m}-${d} ${h}:${i}`);
    },

    filePath(dir, filename) {
        return `${Consts.basefile}/${dir}/${filename}`;
    },

    objToUrlParams(obj, ignoreNull=false) {
        let str = [];

        for (let x in obj) {
            if (ignoreNull && obj[x] === null) continue;
            str.push(`${x}=${obj[x]}`);
        }

        return str.join('&');
    },

    formatToAmount(amount, separator=' ') {
        return amount.toString().split('').reverse().join('').match(/.{1,3}/g).join(separator).split('').reverse().join('')
    }

};

export default Helpers;