import React from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router';

const PublicGuard = ({ user }) => {

    return (
        user ? (<Navigate to="/" replace={true} />) : null
    );
};

const mapStateToProps = state => ({
    user: state.user
});

export default connect(mapStateToProps)(PublicGuard);