import Fetcher from "../Utils/Fetcher";
import Helpers from "../Utils/Helpers";
import Consts from './Consts';
import FakeStore from "./FakeStore";

const OrdersService = {

    getOrders(id, filters) {
        return Fetcher.get(`${Consts.baseapi}/orders${id ? '/' + id : ''}?${filters}`, Helpers.authHeader(FakeStore.token));
    },

    createOrder(values) {
        return Fetcher.post(`${Consts.baseapi}/orders`, values, Helpers.authHeader(FakeStore.token));
    },

    changeOrderStatus(id, newstatus) {
        return Fetcher.get(`${Consts.baseapi}/orders/status/${id}/${newstatus}`, Helpers.authHeader(FakeStore.token));
    },

    printOrderInvoice(id) {
        return Fetcher.get(`${Consts.baseapi}/orders/invoice/${id}`, Helpers.authHeader(FakeStore.token));
    }

};

export default OrdersService;