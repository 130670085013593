import React, { useEffect } from "react";
import { Modal } from 'bootstrap';
import GroupService from "../../Services/GroupsService";
import Helpers from "../../Utils/Helpers";
import Loader from "../../Components/Loader/Loader";
import BtnLoading from "../../Components/BtnLoading/BtnLoading";
import { Form, Formik, Field } from "formik";
import * as Yup from 'yup';

const GroupModal = ({ toedit, askClose }) => {
    const [permissions, setPermissions] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    const doGetPermissions = () => {
        if (permissions.length) {
            return;
        }
        
        setLoading('permissions');

        GroupService.getAllPermissions().then(response => {
            setPermissions(response.permissions);
        })
        .catch(err => {
            Helpers.alert.error(err.message);
            askClose();
        })
        .finally(() => setLoading(false));
    };

    const doSaveGroup = (values) => {
        setLoading('submit');
        
        const datas = {
            id: toedit.id || null,
            name: values.name,
            code: values.code,
            permissions: values.permissions.join(',')
        };

        GroupService.saveGroup(datas).then(response => {
            Helpers.alert.success('Groupe enregistré');
            askClose(!toedit.id ? response.group : {...toedit, ...datas});
        })
        .catch(err => Helpers.alert.error(err.message))
        .finally(() => setLoading(false));
    };

    useEffect(() => {
        const modal = Modal.getOrCreateInstance('#group-modal');

        // load permissions
        toedit && doGetPermissions();

        toedit && modal.show();
        !toedit && modal.hide();
    }, [toedit]);

    return (
        <div className="modal fade" id="group-modal" tabIndex="-1">
            <div className="modal-dialog modal-dialog-md modal-dialog-centered">
                <div className="modal-content">
                    {
                        loading === 'permissions' && (
                            <div className="position-absolute h-100 w-100 bg-white z-index-3 d-flex justify-content-center align-items-center" style={{zIndex:10}}>
                                <Loader />
                            </div>
                        )
                    }

                    {/*  */}

                    <a href="#" className="modal-close" data-dismiss="modal" aria-label="Close" onClick={() => askClose()}>
                        <em className="ti ti-close"></em>
                    </a>
                    <div className="popup-body">
                        <h2 className="m-0">{toedit?.id ? 'Modifier' : 'Créer'} un groupe</h2>
                        <p className="text-gray">{toedit?.id ? 'Modifier un' : 'Créer un nouveau'} groupe d'utilisateur</p>
                        
                        <Formik
                            enableReinitialize={true}
                            initialValues={{
                                name: toedit?.name || '',
                                code: toedit?.code || '',
                                permissions: (toedit?.permissions || '').split(',') || []
                            }}
                            validationSchema={
                                Yup.object().shape({
                                    name: Yup.string().required('Le nom du groupe est requis.'),
                                    code: Yup.string().required('Merci de renseigner le code du groupe.'),
                                    permissions: Yup.array()
                                })
                            }
                            onSubmit={values => doSaveGroup(values)}
                        >
                            {({ errors }) => (
                                <Form>
                                    <div className="input-item input-with-label">
                                        <label className="input-item-label text-exlight">Nom du groupe</label>
                                        <Field
                                            name="name"
                                            className="input-bordered"
                                            type="text" placeholder="Nom du groupe"
                                        />
                                        {errors.name && <span className="input-note text-danger">Entrez le nom du groupe</span>}
                                    </div>
                                    <div className="input-item input-with-label">
                                        <label className="input-item-label text-exlight">Code du groupe</label>
                                        <Field
                                            name="code"
                                            className="input-bordered"
                                            type="text" placeholder="Code du groupe"
                                        />
                                        {errors.code && <span className="input-note text-danger">Entrez le code du groupe</span>}
                                    </div>
                                    <div className="row" style={{ maxHeight: 350, overflow: 'auto' }}>
                                        <div className="col-12">
                                            <label className="input-item-label text-exlight">Permissions</label>
                                        </div>
                                        {
                                            permissions.map(item => (
                                                <div className="col-md-6 mb-2" key={item.id.toString()}>
                                                    <div className="input-item text-left border p-2">
                                                        <Field
                                                            name="permissions"
                                                            value={item.code}
                                                            className="input-checkbox input-checkbox-sm"
                                                            id={'perm_' + item.id}
                                                            type="checkbox"
                                                        />
                                                        <label htmlFor={'perm_' + item.id}>{item.label}</label>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <div className="mt-5">
                                        <button onClick={() => askClose()} type="button" className="btn btn-default btn-dark">Annuler</button>
                                        <BtnLoading
                                            type="submit"
                                            loading={loading === 'submit'}
                                            classes={['btn-primary ml-2']}
                                        >Enregistrer</BtnLoading>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GroupModal;