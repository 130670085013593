import React from "react";
import { Outlet } from "react-router-dom";
import PublicGruard from "../Guards/PublicGruard";
import Logo from '../../Assets/Logo.png';

const PublicLayout = () => {

    return (
        <>
        <PublicGruard />

        <div className="page-ath-wrap">
            <div className="page-ath-content">
                <div className="page-ath-header">
                    <a href="#" className="page-ath-logo">
                        <img src={Logo} alt="logo" width={250} />
                    </a>
                </div>
                <div className="page-ath-form">

                    <Outlet />
                    
                </div>
                <div className="page-ath-footer">
                    <ul className="footer-links">
                        {/* <li>
                            <a href="regular-page.html">Privacy Policy</a>
                        </li>
                        <li>
                            <a href="regular-page.html">Terms</a>
                        </li> */}
                        <li>
                            &copy; <a href="mailto:support@sendem.ci">2022 Sendem dev team.</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="page-ath-gfx">
                <div className="w-100 d-flex justify-content-center">
                    <div className="col-md-8 col-xl-5">
                        <img src="images/ath-gfx.png" alt="" />
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default PublicLayout;