import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { userLogout } from "../../Store/Actions/UserActions";

import Logo from '../../Assets/Logo.png';

const PrivateTopBar = ({ user, dispatch }) => {

    return (
        <div className="topbar is-sticky">
            <div className="container">
                <div className="d-flex justify-content-between align-items-center">
                    <ul className="topbar-nav d-lg-none">
                        <li className="topbar-nav-item relative">
                            <a className="toggle-nav" href="#">
                                <div className="toggle-icon">
                                    <span className="toggle-line"></span>
                                    <span className="toggle-line"></span>
                                    <span className="toggle-line"></span>
                                    <span className="toggle-line"></span>
                                </div>
                            </a>
                        </li>
                    </ul>
                    <Link className="topbar-logo" to="/">
                        <div className="bg-white d-flex justify-content-center align-items-center rounded" style={{width:120, height: 48, boxSizing:'border-box'}}>
                            <img src={Logo} className="rounded" alt="logo" />
                        </div>
                    </Link>
                    <ul className="topbar-nav">
                        <li className="topbar-nav-item relative dropdown">
                            <span className="user-welcome d-none d-lg-inline-block">Salut <b>{user?.infos?.name.split(' ')[0]}</b> !</span>
                            <a className="dropdown-toggle user-thumb" data-bs-toggle="dropdown">
                                <em className="ti ti-user"></em>
                            </a>
                            <div className="toggle-class dropdown-menu dropdown-content dropdown-content-right dropdown-arrow-left user-dropdown">
                                {/* <div className="user-status">
                                    <h6 className="user-status-title">Token balance</h6>
                                        <div className="user-status-balance">12,000,000 <small>TWZ</small>
                                    </div>
                                </div> */}
                                <ul className="user-links">
                                    <li>
                                        <Link to="/profile"><i className="ti ti-id-badge"></i>Mon Profil</Link>
                                    </li>
                                </ul>
                                <ul className="user-links bg-light">
                                    <li>
                                        <a onClick={() => dispatch( userLogout() )} href="#"><i className="ti ti-power-off"></i>Logout </a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    user: state.user
});

export default connect(mapStateToProps)(PrivateTopBar);