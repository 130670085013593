import React from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router';
import FakeStore from '../../Services/FakeStore';

const PrivateGuard = ({ children, user }) => {
    FakeStore.token = user?.token;

    React.useEffect(() => {
        FakeStore.token = user?.token;
    }, [user]);

    return (
        !user ? (<Navigate to="/auth" replace={true} />) : children
    );
};

const mapStateToProps = state => ({
    user: state.user
});

export default connect(mapStateToProps)(PrivateGuard);