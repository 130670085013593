import React from "react";
import { Modal } from 'bootstrap';
import { Form, Formik, Field } from "formik";
import * as Yup from 'yup';
import ProductService from "../../Services/ProductService";
import Helpers from "../../Utils/Helpers";
import Loader from "../../Components/Loader/Loader";
import BtnLoading from "../../Components/BtnLoading/BtnLoading";
import OthersService from "../../Services/OthersService";

const ProductModal = ({ toedit, askClose, categories, setCategories }) => {
    const [loading, setLoading] = React.useState(false);
    const [picture, setPicture] = React.useState('');
    const [formReset, setFormReset] = React.useState(true);

    const doSaveProduct = (values) => {
        setLoading('submit');
        
        const datas = {
            id: toedit.id || null,
            name: values.name,
            category: parseInt(values.category),
            description: values.description,
            picture: picture
        };

        ProductService.saveProduct(datas).then(response => {
            Helpers.alert.success('Article enregistré');
            closeModal(response.product);
        })
        .catch(err => Helpers.alert.error(err.message))
        .finally(() => setLoading(false));
    };

    const closeModal = (value) => {
        askClose(value);
        setPicture('');
    };

    const handleCoverChange = (evt) => {
        if (!evt.target.files.length) return;
        
        setLoading('uploading');

        OthersService.uploadFile(evt.target.files[0], 'Products')
        .then(response => setPicture(response.filename))
        .catch(err => Helpers.alert.error(err.message))
        .finally(() => setLoading(false));
    };

    const getCategories = () => {
        setLoading('categories');

        ProductService.getCategories().then(response => {
            setCategories(response.result);
        })
        .catch(err => {Helpers.alert.error(err.message); askClose();})
        .finally(() => setLoading(false));
    };

    React.useEffect(() => {
        const modal = Modal.getOrCreateInstance('#product-modal');

        toedit && modal.show();
        !toedit && modal.hide();

        setPicture(toedit?.picture);
        setFormReset(!toedit);

        (loading !== 'categories' && !categories.length) && getCategories();
    }, [toedit]);

    return (
        <div className="modal fade" id="product-modal" tabIndex="-1">
            <div className="modal-dialog modal-dialog-lg modal-dialog-centered">
                <div className="modal-content">
                    {
                        (loading === 'categories') && (
                            <div className="position-absolute h-100 w-100 bg-white z-index-3 d-flex justify-content-center align-items-center" style={{zIndex:10}}>
                                <Loader />
                            </div>
                        )
                    }

                    {/*  */}

                    <a href="#" className="modal-close" data-dismiss="modal" aria-label="Close" onClick={() => closeModal()}>
                        <em className="ti ti-close"></em>
                    </a>
                    <div className="popup-body">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="position-relative">
                                    <img
                                        src={picture ? Helpers.filePath('Products', picture) : 'http://via.placeholder.com/200x200'}
                                        alt=""
                                        className="w-100 rounded"
                                    />
                                    <input type="file" className="d-none" id="cat_cover" onChange={handleCoverChange} />
                                    <label
                                        htmlFor="cat_cover"
                                        className="position-absolute border-0 user-thumb shadow cursor-pointer"
                                        style={{bottom:10, right:10}}
                                    >
                                        {loading !== 'uploading' && <em className="ti ti-image"></em>}
                                        {loading === 'uploading' && <Loader width={12} color="#fff" />}
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-8">
                                <h2 className="m-0">{toedit?.id ? 'Modifier' : 'Créer'} une article</h2>
                                <p className="text-gray">{toedit?.id ? 'Modifier un' : 'Créer une nouvel'} article</p>
                                
                                <Formik
                                    enableReinitialize={formReset}
                                    initialValues={{
                                        name: toedit?.name || '',
                                        category: parseInt(toedit?.category_id || 0),
                                        description: toedit?.description || '',
                                        picture: picture || ''
                                    }}
                                    validationSchema={
                                        Yup.object().shape({
                                            name: Yup.string().required('Le nom de l\'article est requise.')
                                        })
                                    }
                                    onSubmit={(values, x) => {doSaveProduct(values); x.resetForm();}}
                                >
                                    {({ errors, touched, resetForm }) => (
                                        <Form>
                                            <div className="input-item input-with-label">
                                                <label className="input-item-label text-exlight">Nom de l'article</label>
                                                <Field
                                                    name="name"
                                                    className="input-bordered"
                                                    type="text" placeholder="Nom de l'article"
                                                />
                                                {touched.name && errors.name && <span className="input-note text-danger">{errors.name}</span>}
                                            </div>
                                            <div className="input-item input-with-label">
                                                <label className="input-item-label">Catégorie</label>
                                                <div className="select-wrapper">
                                                    <Field
                                                        as="select"
                                                        name="category"
                                                        className="input-bordered"
                                                    >
                                                        <option value={0} disabled>Choisir une catégorie</option>
                                                    {
                                                        categories.map(item => (
                                                            <option key={item.id.toString()} value={item.id}>{item.name}</option>
                                                        ))
                                                    }
                                                    </Field>
                                                </div>
                                            </div>
                                            <div className="input-item input-with-label">
                                                <label className="input-item-label text-exlight">Description de l'article</label>
                                                <Field
                                                    name="description"
                                                    className="input-bordered"
                                                    type="textarea" placeholder="description de l'article"
                                                />
                                            </div>
                                            <div className="mt-5">
                                                <button onClick={() => {closeModal(); resetForm();}} type="button" className="btn btn-default btn-dark">Annuler</button>
                                                <BtnLoading
                                                    type="submit"
                                                    loading={loading === 'submit'}
                                                    classes={['btn-primary ml-2']}
                                                >Enregistrer</BtnLoading>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductModal;