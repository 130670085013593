const Fetcher = {
    abortSignal: null,

    query(type, uri, datas={}, headers={}, containsfile=false) {
        const values = Fetcher.compileDatas(datas, !containsfile);
        const header = Fetcher.compileHeader({
            ...(containsfile ? {} : {'Content-Type': 'application/json'}),
            ...headers
        });

        const promise = new Promise((resolve, reject) => {
            let q = {
                method: type,
                headers: header,
                cache: 'no-cache',
                mode: 'cors',
                signal: Fetcher.abortSignal
            };

            if (['POST', 'PUT'].indexOf(type) !== -1) {
                q.body = values;
            }

            fetch(uri, q)
            .then(async (res) => {
                if (res.headers.get('Content-Type').indexOf('json') !== -1) {
                    return res.json();
                }
                else {
                    reject({
                        error: true,
                        message: await res.text()
                    });
                }
            })
            .then(result => {
                result.error && reject(result);
                !result.error && resolve(result);
            })
            .catch(error => {
                reject(error);
            });
        });

        return promise;
    },

    get(uri, headers={}) {
        return Fetcher.query('GET', uri, {}, headers);
    },

    post(uri, datas={}, headers={}, containsfile=false) {
        return Fetcher.query('POST', uri, datas, headers, containsfile);
    },

    put(uri, datas={}, headers={}) {
        return Fetcher.query('PUT', uri, datas, headers);
    },

    delete(uri, headers={}) {
        return Fetcher.query('DELETE', uri, {}, headers);
    },

    compileDatas(datas, tojson=true) {
        if (tojson) {
            return JSON.stringify(datas);
        }
        
        let compiled = new FormData();

        for (let x in datas) {
            compiled.append(x, datas[x]);
        }

        return compiled;
    },

    compileHeader(headers) {
        return new Headers(headers);
    }

};

export default Fetcher;