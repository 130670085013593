import React from "react";

const PrivateFooter = () => {

    return (
        <div className="footer-bar">
            <div className="container">
                <div className="row align-items-center justify-content-center">
                    <div className="col-md-8">
                        <ul className="footer-links">
                            <li>
                                <a href="#">A propos de Store.Ma</a>
                            </li>
                            <li>
                                <a href="#">FAQ</a>
                            </li>
                            <li>
                                <a href="regular-page.html">Politique de confidentialité</a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-4 mt-2 mt-sm-0">
                        <div className="d-flex justify-content-between justify-content-md-end align-items-center guttar-25px pdt-0-5x pdb-0-5x">
                            <div className="copyright-text">&copy; <a href="mailto:support@sendem.ci">2022 Sendem dev team.</a></div>
                            {/* <div className="lang-switch relative">
                                <a href="_blank.html#" className="lang-switch-btn toggle-tigger">En <em className="ti ti-angle-up"></em></a>
                                <div className="toggle-class dropdown-content dropdown-content-up">
                                    <ul className="lang-list">
                                        <li>
                                            <a href="_blank.html#">Fr</a>
                                        </li>
                                        <li>
                                            <a href="_blank.html#">Bn</a>
                                        </li>
                                        <li>
                                            <a href="_blank.html#">Lt</a>
                                        </li>
                                    </ul>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PrivateFooter;