import React from "react";

const Loader = ({ color='primary', width=40 }) => {

    return (
        <div className={`spinner-border text-${color}`} role="status" style={{ width, height: width }}>
            <span className="sr-only">Loading...</span>
        </div>
    );
};

export default Loader;