import { useFormik } from 'formik';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import AuthService from '../../Services/AuthService';
import BtnLoading from '../../Components/BtnLoading/BtnLoading';
import FlatAlert from '../../Components/FlatAlert/FlatAlert';
import { userLogin } from '../../Store/Actions/UserActions';

const Login = ({ dispatch }) => {
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(null);
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {email: '', password: ''},
        validationSchema: Yup.object().shape({
            email: Yup.string().required().email(),
            password: Yup.string().required().min(6)
        }),
        onSubmit(values) {
            doLogin(values);
        }
    });

    const doLogin = (values) => {
        setError(null);
        setLoading(true);

        AuthService.login(values).then((values) => {
            dispatch(userLogin(values.user));
            navigate('/');
        })
        .catch(err => {
            setError(err.message);
        })
        .finally(() => {
            setLoading(false);
        });
    };

    return (
        <>
            <h2 className="page-ath-heading">
                Connectez vous 
                <small>avec votre compte <b className="text-primary">Store</b></small>
            </h2>
            <form onSubmit={formik.handleSubmit}>
                {error && <FlatAlert type="danger" message={error} />}

                <div className="input-item">
                    <input
                        name="email"
                        type="email"
                        placeholder="Votre Email"
                        className="input-bordered"
                        onChange={formik.handleChange}
                        value={formik.values.email}
                    />
                </div>
                <div className="input-item">
                    <input
                        name="password"
                        type="password"
                        placeholder="Votre mot de passe"
                        className="input-bordered"
                        onChange={formik.handleChange}
                        value={formik.values.password}
                    />
                </div>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="input-item text-left">
                        <input className="input-checkbox input-checkbox-md" id="remember-me" type="checkbox" />
                        <label htmlFor='remember-me'>Se souvenir de moi</label>
                    </div>
                    <div>
                        <Link to="/auth/forgot-password">Mot de passe oublié ?</Link>
                        <div className="gaps-2x"></div>
                    </div>
                </div>
                <BtnLoading
                    classes={['btn-primary', 'btn-block']}
                    loading={loading}
                    type="submit"
                >Se connecter</BtnLoading>
            </form>
            {/* <div className="sap-text">
                <span>Or Sign In With</span>
            </div>
            <ul className="row guttar-20px guttar-vr-20px">
                <li className="col">
                    <a href="sign-in.html#" className="btn btn-outline btn-dark btn-facebook btn-block">
                        <em className="fab fa-facebook-f"></em>
                        <span>Facebook</span>
                    </a>
                </li>
                <li className="col">
                    <a href="sign-in.html#" className="btn btn-outline btn-dark btn-google btn-block">
                        <em className="fab fa-google"></em>
                        <span>Google</span>
                    </a>
                </li>
            </ul> */}
            <div className="gaps-2x"></div>
            <div className="gaps-2x"></div>
            <div className="form-note">
                Vous n'avez pas de compte ? <Link to="/auth/register"><strong>Inscrivez-vous</strong></Link>
            </div>
        </>
    );
};

export default connect()(Login);