import Fetcher from "../Utils/Fetcher";
import Helpers from "../Utils/Helpers";
import Consts from './Consts';
import FakeStore from "./FakeStore";

const FundsService = {

    getExpenses(filters='') {
        return Fetcher.get(`${Consts.baseapi}/expenses?${filters}`, Helpers.authHeader(FakeStore.token));
    },

    saveExpense(datas) {
        const func = datas.id ? Fetcher.put : Fetcher.post;
        const suffix = datas.id ? '/' + datas.id : '';
        return func(`${Consts.baseapi}/expenses${suffix}`, datas, Helpers.authHeader(FakeStore.token));
    },

    activateExpense(id) {
        return Fetcher.put(`${Consts.baseapi}/expenses/${id}/activate`, {}, Helpers.authHeader(FakeStore.token));
    },

    rejectExpense(id) {
        return Fetcher.put(`${Consts.baseapi}/expenses/${id}/reject`, {}, Helpers.authHeader(FakeStore.token));
    },

    deleteExpense(id) {
        return Fetcher.delete(`${Consts.baseapi}/expenses/${id}`, Helpers.authHeader(FakeStore.token));
    },

};

export default FundsService;