import React, { useEffect, useState } from "react";
import {Link} from 'react-router-dom';
import { useCountUp } from 'react-countup';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import {
    Chart,
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle
  } from 'chart.js';
import DashboardService from "../../Services/DashboardService";
import Helpers from "../../Utils/Helpers";
import Consts from "../../Services/Consts";

Chart.register(
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle
);

const Dashboard = () => {
    const chartColors = ['#444e86', '#955196', '#dd5182', '#ff6e54', '#ffa600', '#665191', '#a05195'];
    const totalCount = useCountUp({ ref: 'totalCount', start: 0, delay: 1, formattingFn: Helpers.formatToAmount });
    const soldCount = useCountUp({ ref: 'soldCount', start: 0, delay: 1, formattingFn: Helpers.formatToAmount });

    const [loading, setLoading] = useState(false);
    const [lastOrders, setLastOrders] = useState([]);
    const [sold, setSold] = useState([]);
    const [dashCounts, setDashCounts] = useState({
        totalAmount:0,
        todaySoldAmount: 0,
        todaySoldArticles: 0,
        ordersByStatus: [],
        monthySold: []
    });

    const generateLineChart = (datas) => {
        const el = window.document.getElementById('myChart1');
        return new Chart(el, {
            type: 'line',
            data: {
                labels: datas.map(x => Helpers.parseDate(x.day).format("DD/MM/YYYY")),
                datasets: [{
                    label: 'Vente du mois',
                    data: datas.map(x => parseFloat(x.total_amount)),
                    borderColor: ['rgb(30,104,206)'],
                    borderWidth: 3,
                    lineTension: 0.4
                }]
            },
            options: {
                scales: {
                    x: {display: false},
                    y: {display: true}
                },
                plugins: {
                    legend: {display: false},
                }
            }
        });
    };

    const generatePieChart = (datas) => {
        return new Chart(document.getElementById("myChart2"), {
            type: 'doughnut',
            data: {
                labels: datas.map(x => Consts.statusLabel[x.status]),
                datasets: [{
                    label: "Population (millions)",
                    backgroundColor: datas.map((x,y) => chartColors[y]),
                    data: datas.map(x => parseFloat(x.nbr_orders))
                }]
            },
            options: {
                plugins: {
                    legend: {display: false}
                }
            }
        });
    };

    useEffect(() => {
        let barCT = null;
        let pieCT = null;

        setLoading('dashinfos');

        DashboardService.getDashInfos().then(response => {
            setLastOrders(response.last_orders);
            setSold(response.sold);
            setDashCounts({
                totalAmount: response.total_amount,
                todaySoldAmount: response.today_amount,
                todaySoldArticles: response.today_sold_articles,
                ordersByStatus: response.orders_by_status,
                monthySold: response.monthy_sold
            });

            barCT = generateLineChart(response.monthy_sold);
            pieCT = generatePieChart(response.orders_by_status);

            totalCount.update(parseFloat(response.total_amount));
            soldCount.update(parseFloat(response.today_amount));
        })
        .catch(err => Helpers.alert.error(err.message))
        .finally(() => setLoading(false));

        return () => {
            barCT && barCT.destroy();
            pieCT && pieCT.destroy();
        }
    }, []);

    return (
        <>
            <div className="container">
                {true && (
                    <>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="token-statistics card card-token height-auto shadow">
                                <div className="card-innr">
                                    <div className="token-balance token-balance-with-icon">
                                        <div className="token-balance-icon">
                                            <i className="ti ti-money" style={{fontSize: 25}}></i>
                                        </div>
                                        <div className="token-balance-text">
                                            <h6 className="card-sub-title">Montant disponible</h6>
                                            <span className="lead">
                                                <b id="totalCount" />
                                                <span>F cfa</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card height-auto shadow">
                                <div className="card-innr px-3">
                                    <div className="token-balance token-balance-with-icon">
                                        <div className="token-balance-icon bg-dark">
                                            <i className="ti ti-shopping-cart text-white" style={{fontSize: 25}}></i>
                                        </div>
                                        <div className="token-balance-text">
                                            <h6 className="card-sub-title">Ventes du jour (ttc)</h6>
                                            <span className="lead text-dark">
                                                <b id="soldCount" />
                                                <span>F cfa</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card height-auto shadow">
                                <div className="card-innr px-3">
                                    <div className="token-balance token-balance-with-icon">
                                        <div className="token-balance-icon bg-dark">
                                            <i className="ti ti-tag text-white" style={{fontSize: 25}}></i>
                                        </div>
                                        <div className="token-balance-text">
                                            <h6 className="card-sub-title">Articles vendus</h6>
                                            <span className="lead text-dark">{dashCounts.todaySoldArticles || 0}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*  */}
                    {/*  */}

                    <div className="row">
                        <div className="col-xl-8 col-lg-7">
                            <div className="token-sale-graph card card-full-height">
                                <div className="card-innr">
                                    <div className="card-head has-aside">
                                        <h4 className="card-title">Ventes du mois</h4>
                                    </div>
                                    <div className="chart-tokens">
                                        <canvas id="myChart1" height={0}></canvas>
                                    </div>
                                    {loading === 'dashinfos' && (
                                        <SkeletonTheme baseColor="#f1f1f1" highlightColor="#fefefe" height={350}>
                                            <Skeleton count={1} />
                                        </SkeletonTheme>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-5">
                            <div className="token-sale-graph card card-full-height">
                                <div className="card-innr">
                                    <div className="card-head has-aside">
                                        <h4 className="card-title">Status des Commandes</h4>
                                    </div>
                                    <div className="chart-tokens mt-3">
                                        <canvas id="myChart2" height={0}></canvas>
                                    </div>
                                    {loading === 'dashinfos' && (
                                        <SkeletonTheme baseColor="#f1f1f1" highlightColor="#fefefe" height={350}>
                                            <Skeleton count={1} />
                                        </SkeletonTheme>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*  */}
                    {/*  */}

                    <div className="row">
                        <div className="col-xl-8 col-lg-7">
                            <div className="token-transaction card card-full-height shadow-sm">
                                <div className="card-innr">
                                    <div className="card-head has-aside">
                                        <h4 className="card-title">Dernières Commandes</h4>
                                        <div className="card-opt">
                                            <Link to="/orders" className="link ucap">Voir tout <em className="fas fa-angle-right ml-2"></em></Link>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table tnx-table">
                                            <thead>
                                                <tr>
                                                    <th>No de commande</th>
                                                    <th>Client</th>
                                                    <th>Montant</th>
                                                    <th className="d-none d-sm-table-cell tnx-date">Date</th>
                                                    <th className="tnx-type">
                                                        <div className="tnx-type-text"></div>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    lastOrders.map((x,y) => (
                                                        <tr key={y.toString()}>
                                                            <td>
                                                                <div className="d-flex align-items-center">
                                                                    <div className="data-state data-state-approved"></div>
                                                                    <span className="lead">#00000{x.id}</span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <span className="sub">{x.client_name || 'Inconnu'}</span>
                                                            </td>
                                                            <td>
                                                                <span>
                                                                    <span className="lead">{Helpers.formatToAmount(x.total_amount)}</span>
                                                                    <span className="sub">Fcfa</span>
                                                                </span>
                                                            </td>
                                                            <td className="d-none d-sm-table-cell tnx-date">
                                                                <span className="sub sub-s2">{Helpers.parseDate(x.order_date).format("DD/MM/YYYY à hh:mm")}</span>
                                                            </td>
                                                            <td className="tnx-type">
                                                                <span className="tnx-type-md badge badge-outline badge-success badge-md">Payée</span>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-5">
                            <div className="token-transaction card card-full-height shadow-sm">
                                <div className="card-innr">
                                    <div className="card-head has-aside">
                                        <h4 className="card-title">Les plus vendus</h4>
                                    </div>
                                    <table className="table tnx-table">
                                        <thead>
                                            <tr>
                                                <th>Article</th>
                                                <th className="d-none d-sm-table-cell tnx-date text-right">Vendus</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                sold.map((x,y) => (
                                                    <tr key={y.toString()}>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                            <img
                                                                src={x.product_thumb ? Helpers.filePath('Products', x.product_thumb) : 'http://via.placeholder.com/100x100'}
                                                                className="rounded-circle mr-2"
                                                                width={30}
                                                            />
                                                                <span className="lead">{x.product_name}</span>
                                                            </div>
                                                        </td>
                                                        <td className="text-right">
                                                            <span className="lead">{x.quantity_sold}</span>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    </>
                )}
            </div>
        </>
    );
};

export default Dashboard;