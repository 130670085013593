import React from "react";
import './BtnLoading.css';

const BtnLoading = (props) => {

    return (
        <button
            type={props.type || "button"}
            className={['BtnLoading btn py-1', ...(props.classes || [])].join(' ')}
            onClick={props.onClick || null}
            {...props}
        >
            {props.loading && <i className='bx bx-loader bx-sm bx-spin mt-1'></i>}
            {!props.loading && props.children}
        </button>
    );
};

export default BtnLoading;