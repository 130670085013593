import Fetcher from "../Utils/Fetcher";
import Helpers from "../Utils/Helpers";
import Consts from './Consts';
import FakeStore from "./FakeStore";

const GroupService = {

    getGroups() {
        return Fetcher.get(`${Consts.baseapi}/groups`, Helpers.authHeader(FakeStore.token));
    },

    getAllPermissions() {
        return Fetcher.get(`${Consts.baseapi}/permissions`, Helpers.authHeader(FakeStore.token));
    },

    saveGroup(datas) {
        const func = datas.id ? Fetcher.put : Fetcher.post;
        const suffix = datas.id ? '/' + datas.id : '';
        return func(`${Consts.baseapi}/groups${suffix}`, datas, Helpers.authHeader(FakeStore.token));
    },

    toggleGroup(id) {
        return Fetcher.put(`${Consts.baseapi}/groups/${id}/toggle`, {}, Helpers.authHeader(FakeStore.token));
    },

    deleteGroup(id) {
        return Fetcher.delete(`${Consts.baseapi}/groups/${id}`, Helpers.authHeader(FakeStore.token));
    },

};

export default GroupService;