import { useFormik } from "formik";
import React from "react";
import * as Yup from 'yup';
import BsSpinner from "../../Components/BsSpinner/BsSpinner";
import BtnLoading from "../../Components/BtnLoading/BtnLoading";
import Loader from "../../Components/Loader/Loader";
import EntityService from "../../Services/EntityService";
import Helpers from "../../Utils/Helpers";
import '../Profile/Profile.scss';

const Settings = () => {
    const [loading, setLoading] = React.useState(false);
    const [entity, setEntity] = React.useState({});

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            logo: '',
            address: '',
            phone: '',
            currency: ''
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required('Entrez votre nom.').min(2, 'Nom pas correct.')
        }),
        onSubmit(values) {
            doUpdateEntity(values);
        }
    });

    const setInfos = (infos) => {
        formik.setFieldValue('name', infos?.name);
        formik.setFieldValue('email', infos?.email);
        formik.setFieldValue('address', infos?.address);
        formik.setFieldValue('phone', infos?.phone);
        formik.setFieldValue('logo', infos?.logo);
        formik.setFieldValue('currency', infos?.currency);
    };

    const doUpdateEntity = (values) => {
        setLoading('UPDATE-ENTITY');

        EntityService.updateEntityInfos(values).then(() => {
            setEntity(values);
            Helpers.alert.success('Vos informations ont bien été mises à jour.');
        })
        .catch(err => Helpers.alert.error(err.message))
        .finally(() => setLoading(false));
    };

    const doEntityLogoChange = (evt) => {
        setLoading('ENTITY_LOGO');

        EntityService.updateEntityLogo({file: evt.target.files[0]})
        .then(result => {
            setEntity(state => ({...state, logo: result.picture}));
            Helpers.alert.success('Logo mis à jour.');
        })
        .catch(err => Helpers.alert.error(err.message))
        .finally(() => setLoading(false));
    };

    const getEntityInfos = () => {
        setLoading('GET-ENTITY');

        EntityService.getEntity().then(response => {
            setEntity(response.result);
            setInfos(response.result);
        })
        .catch(err => Helpers.alert.error(err.message))
        .finally(() => setLoading(false));
    };

    React.useEffect(() => {
        getEntityInfos();
    }, []);

    return (
        <div className="container ProfilePage">
            {loading !== 'GET-ENTITY' && (
                <div className="row">
                    <div className="col-md-9">
                        <div className="card">
                            <div className="card-innr">
                                <div className="card-head">
                                    <h4 className="card-title">Paramètrages de la boutique</h4>
                                    <p>Parametrez votre boutique en ligne.</p>
                                </div>
                                <form onSubmit={formik.handleSubmit}>
                                    <div className="row">
                                        <div className="col-md-12 mb-2">
                                            <div className="input-item input-with-label">
                                                <label className="input-item-label">Nom et prénoms</label>
                                                <input
                                                    name="name"
                                                    className="input-bordered"
                                                    type="text"
                                                    placeholder="Votre nom"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.name}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <div className="input-item input-with-label">
                                                <label className="input-item-label">E-mail</label>
                                                <input
                                                    name="email"
                                                    className="input-bordered"
                                                    type="text"
                                                    placeholder="Votre email"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.email}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <div className="input-item input-with-label">
                                                <label className="input-item-label">Téléphone</label>
                                                <input
                                                    name="phone"
                                                    className="input-bordered"
                                                    type="text"
                                                    placeholder="Votre téléphone"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.phone}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-12 mb-2">
                                            <div className="input-item input-with-label">
                                                <label className="input-item-label">Adresse</label>
                                                <textarea
                                                    name="address"
                                                    className="input-bordered"
                                                    type="text"
                                                    placeholder="Votre adresse"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.address}
                                                ></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div className="row mt-4">
                                        <div className="col-12 mb-3">
                                            <h6 className="card-title">Monnaie</h6>
                                            <p>A propos de la monnaie</p>
                                        </div>
                                        <div className="col-md-12 mb-2">
                                            <div className="input-item input-with-label">
                                                <label className="input-item-label">Monnaie</label>
                                                <input
                                                    name="currency"
                                                    className="input-bordered"
                                                    type="text"
                                                    placeholder="La monnaie à utiliser"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.currency}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <BtnLoading
                                        type="submit"
                                        classes={['btn-primary']}
                                        loading={loading === 'UPDATE-ENTITY'}
                                    >Enregistrer</BtnLoading>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="content-area card">
                            <div className="card-innr">
                                <div className="profile-picture">
                                    <img
                                        alt=""
                                        src={
                                            entity?.logo ?
                                                Helpers.filePath('Entities', entity.logo) :
                                                'http://via.placeholder.com/200x200'}
                                        />
                                </div>
                                    
                                <label htmlFor="entity_logo" className="btn btn-primary btn-block mt-3">
                                    <input type="file" id="entity_logo" className="d-none" onChange={doEntityLogoChange} />
                                    {loading !== 'ENTITY_LOGO' && <><i className="ti ti-camera"></i> &nbsp; Logo de la boutique</>}
                                    {loading === 'ENTITY_LOGO' && <BsSpinner type="white" size="sm" />}
                                </label>
                            </div>
                        </div>
                        <div className="content-area card">
                            <div className="card-innr">
                                <div className="card-head">
                                    {/* <h4 className="card-title">Resumé des infos</h4> */}
                                    {/* <p>A propos de vous</p> */}
                                </div>
                                <div className="">
                                    <h6 className="card-title card-title-sm">Nom de la boutique</h6>
                                    <p>{entity?.name}</p>
                                </div>
                                <div className="mt-3">
                                    <h6 className="card-title card-title-sm">Téléphone</h6>
                                    <p>{entity?.phone || 'Non précisé'}</p>
                                </div>
                                <div className="mt-3">
                                    <h6 className="card-title card-title-sm">Adresse email</h6>
                                    <p>{entity?.email || 'Non précisé'}</p>
                                </div>
                                <div className="mt-3">
                                    <h6 className="card-title card-title-sm">Adresse</h6>
                                    <p>{entity?.address || 'Non précisé'}</p>
                                </div>
                                <div className="mt-3">
                                    <h6 className="card-title card-title-sm">Monnaie</h6>
                                    <p>{entity?.currency || 'Non précisé'}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {loading === 'GET-ENTITY' && (
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-innr d-flex align-items-center justify-content-center" style={{height:500}}>
                                <Loader />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
export default Settings;