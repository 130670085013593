import React from 'react';
import $ from 'jquery';
import Loader from '../../Components/Loader/Loader';
import OthersService from '../../Services/OthersService';
import Helpers from '../../Utils/Helpers';
import BtnLoading from '../../Components/BtnLoading/BtnLoading';

const Activities = () => {
    const nbPerPage = 15;
    const [activities, setActivities] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [start, setStart] = React.useState('');
    const [end, setEnd] = React.useState('');
    const [page, setPage] = React.useState(1);

    const getActivities = (loadingtype='activities') => {
        setPage(1);
        setLoading(loadingtype);

        OthersService.getActivities(start, end).then(response => {
            setActivities(response.result);
        })
        .catch(err => Helpers.alert.error(err.message))
        .finally(() => setLoading(false));
    };

    const toggleFilters = () => {
        $('.filters').slideToggle(300);
    };

    React.useEffect(() => {
        getActivities();
    }, []);

    return (
        <>
        <div className="card">
            <div className="card-innr">
                <div className="card-head border-bottom">
                    <button onClick={() => toggleFilters()} className="float-right badge badge-primary badge-lg">Montrer les filtres</button>
                    {/*  */}
                    <h4 className="card-title">Activités dans votre boutique</h4>
                    <p>Trouvez ci-dessous l'activité dans votre boutique.</p>
                </div>
            {
                loading !== 'activities' && !!activities.length && (
                    <>
                    <div className="filters border-top border-bottom py-3" style={{display:'none'}}>
                        {/* <h5 className="">Appliquer des filtres</h5> */}
                        <div className="row">
                            <div className="col-sm-4">
                                <div className="input-item pb-0">
                                    <div className="relative">
                                        <input
                                            value={start}
                                            onChange={(evt) => setStart(evt.target.value)}
                                            className="input-bordered pl-5"
                                            type="date" id="date-of-birth" name="date-of-birth"
                                        />
                                        <span className="input-icon input-icon-left date-picker-icon mt-1">De</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="input-item pb-0">
                                    <div className="relative">
                                        <input
                                            value={end}
                                            onChange={(evt) => setEnd(evt.target.value)}
                                            className="input-bordered pl-5"
                                            type="date" id="date-of-birth" name="date-of-birth"
                                        />
                                        <span className="input-icon input-icon-left date-picker-icon mt-1">À</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <BtnLoading
                                    type="button"
                                    classes={['btn-primary',  'btn-block']}
                                    onClick={() => getActivities('filtering')}
                                    loading={loading === 'filtering'}
                                >Filtrer</BtnLoading>
                            </div>
                        </div>
                    </div>
                    <div className="mt-3 table-responsive">
                        <table className="table" style={{borderCollapse: 'separate', borderSpacing: '20px 0px'}}>
                            <thead>
                                <tr>
                                    <th role="th">Date</th>
                                    <th role="th">Action</th>
                                    <th role="th">Utilisateur</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                activities.slice( (page - 1)*nbPerPage, (page - 1)*nbPerPage + nbPerPage ).map(item => (
                                    <tr key={item.id.toString()}>
                                        <td>{Helpers.parseDate(item.act_date).format('DD/MM/YYYY à HH:mm')}</td>
                                        <td>{item.act_label}</td>
                                        <td>{item.user_name}</td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                    </div>
                    <div className="mt-3 border-top pt-3">
                        <button
                            disabled={page === 1}
                            onClick={() => setPage(page - 1)}
                            className="badge badge-primary badge-sm"
                        >Précédent</button>
                        <button
                            disabled={nbPerPage * (page + 1) >= activities.length}
                            onClick={() => setPage(page + 1)}
                            className="badge badge-primary badge-sm ml-2"
                        >Suivant</button>
                    </div>
                    </>
                )
            }
            {
                loading === 'activities' && (
                    <div className="d-flex justify-content-center align-items-center" style={{height: 300}}>
                        <div className="p-4 text-center">
                            <Loader />
                        </div>
                    </div>
                )
            }
            {
                !loading && !activities.length && (
                    <div className="alert alert-primary mt-4">Aucune information disponible.</div>
                )
            }
            </div>
        </div>
        </>
    );
};

export default Activities;