import React from 'react';

const BsSpinner = ({type='light', size='md'}) => {

    return (
        <div className={'spinner-border text-'+ type +' spinner-border-' + size} role="status">
            <span className="sr-only">Loading...</span>
        </div>
    );
};

export default BsSpinner;