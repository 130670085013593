import React from "react";
import { Link } from "react-router-dom";

const PrivateNavBar = () => {

    React.useEffect(() => {
        const C = window.$(".toggle-nav");
        const b = window.$(".navbar");
        C.length > 0 && C.on("click", function(t) {
            C.toggleClass("active"); b.toggleClass("active"); t.preventDefault();
        })
    }, []);

    return (
        <div className="navbar">
            <div className="container">
                <div className="navbar-innr">
                    <ul className="navbar-menu">
                        <li>
                            <Link to="/"><i className="bx bxs-dashboard bx-sm mr-1"></i> Dashboard </Link>
                        </li>
                        <li className="has-dropdown">
                            <Link to="/orders"><em className="bx bxs-cart bx-sm mr-1"></em> Commandes </Link>
                            <ul className="navbar-dropdown">
                                <li>
                                    <Link to="/orders">Liste des commandes</Link>
                                </li>
                                <li>
                                    <Link to="/orders/new">Créer une commande</Link>
                                </li>
                            </ul>
                        </li>
                        <li className="has-dropdown">
                            <Link to="/products" className="drop-toggle"><em className="bx bxs-purchase-tag bx-sm mr-1"></em> Articles </Link>
                            <ul className="navbar-dropdown">
                                <li>
                                    <Link to="/products">Liste des articles</Link>
                                </li>
                                <li>
                                    <Link to="/products/add">Ajouter un article</Link>
                                </li>
                                <li>
                                    <Link to="/products/categories">Categories</Link>
                                </li>
                                <li>
                                    <Link to="/products/stocks">Stocks</Link>
                                </li>
                            </ul>
                        </li>
                        <li className="has-dropdow">
                            <Link to="/funds" className="drop-toggle"><em className="bx bxs-wallet bx-sm mr-1"></em> Caisse </Link>
                            {/* <ul className="navbar-dropdown">
                                <li>
                                    <Link to="/fund">Liste des articles</Link>
                                </li>
                                <li>
                                    <Link to="/products/add">Ajouter un article</Link>
                                </li>
                                <li>
                                    <Link to="/products/categories">Categories</Link>
                                </li>
                                <li>
                                    <Link to="/products/stocks">Stocks</Link>
                                </li>
                            </ul> */}
                        </li>
                        <li className="has-dropdown">
                            <Link to="/users"><em className="bx bxs-user-badge bx-sm mr-1"></em> Utilisateurs </Link>
                            <ul className="navbar-dropdown">
                                <li>
                                    <Link to="/users/groups">Groupes d'utilisateurs</Link>
                                </li>
                                <li>
                                    <Link to="/users/logs">Activités</Link>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <Link to="/settings"><em className="bx bxs-brightness bx-sm mr-1"></em> Paramètres </Link>
                        </li>
                    </ul>
                    <ul className="navbar-btns">
                        <li className="">
                            <a href="mailto:support@sendem.ci?subject=SALES.MA-SUGGESTION" className="badge badge-outline badge-primary badge-lg">
                                <em className="text-primary ti ti-headphone mgr-1x"></em>
                                <span className="text-primary">Contactez nous</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default PrivateNavBar;