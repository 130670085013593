import React, { useState } from "react";
import { Link } from "react-router-dom";
import BtnLoading from "../../Components/BtnLoading/BtnLoading";
import Loader from "../../Components/Loader/Loader";
import Consts from "../../Services/Consts";
import OrdersService from "../../Services/OrdersService";
import Helpers from "../../Utils/Helpers";

const Orders = () => {
    const [loading, setLoading] = React.useState(false);
    const [orders, setOrders] = React.useState([]);
    const [page, setPage] = React.useState(1);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [ordersStatus, setOrdersStatus] = useState('');
    const [plength, setPLength] =  useState(0);

    const loadOrders = (id, letPage=false) => {
        setLoading('orders');

        const filters = {
            start: startDate || null,
            end: endDate || null,
            page: letPage || null,
            status: ordersStatus || null
        };

        OrdersService.getOrders(id, Helpers.objToUrlParams(filters, true)).then(response => {
            setOrders(response.result);
            setPLength(response.plength);
            !letPage && setPage(1);
        })
        .catch(err => Helpers.alert.error(err.message))
        .finally(() => setLoading(false));
    };

    const changeOrderState = (order, newstate) => {
        setLoading('changestate-' + order.id);

        OrdersService.changeOrderStatus(order.id, newstate).then(response => {
            setOrders(state => state.map(x => {
                return x.id === order.id ? {...x, status: newstate} : x;
            }));
        })
        .catch(err => Helpers.alert.error(err.message))
        .finally(() => setLoading(false));
    };

    const printInvoice = (orderId) => {
        setLoading('printing-invoice-' + orderId);

        OrdersService.printOrderInvoice(orderId).then(response => {
            window.open(Consts.baseuri + '/Temp/Reports/Orders/' + response.message, "width=300,height=300");
        })
        .catch(err => Helpers.alert.error(err.message))
        .finally(() => setLoading(false));
    };

    React.useEffect(() => {
        loadOrders();
    }, [startDate, endDate, ordersStatus]);

    return (
        <>
            <div className="Orders">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card bg-light">
                                <div className="card-innr">
                                    <div className="card-header bg-light p-0 pb-3">
                                        <div className="float-right">
                                            <Link to="/orders/new" className="float-right btn btn-primary btn-sm">Créer un commande</Link>
                                        </div>
                                        <h2 className="font-weight-bold m-0">Liste des commandes</h2>
                                        <p>Retrouvez la liste des commandes effectuées dans votre boutique.</p>
                                    </div>
                                    <div className="card-header bg-light p-0 pb-3 mt-3">
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <div className="relative">
                                                    <label>A partir du:</label>
                                                    <span className="input-icon input-icon-left" style={{top:'unset', bottom:-8}}>
                                                        <em className="ti ti-calendar"></em>
                                                    </span>
                                                    <input
                                                        className="input-bordered"
                                                        type="date" id="search-field"
                                                        onChange={e => setStartDate(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="relative">
                                                    <label>Au:</label>
                                                    <span className="input-icon input-icon-left" style={{top:'unset', bottom:-8}}>
                                                        <em className="ti ti-calendar"></em>
                                                    </span>
                                                    <input
                                                        className="input-bordered"
                                                        type="date" id="search-field"
                                                        onChange={e => setEndDate(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="relative">
                                                    <label>Status de la commande:</label>
                                                    <span className="input-icon input-icon-left" style={{top:'unset', bottom:-8}}>
                                                        <em className="ti ti-angle-down"></em>
                                                    </span>
                                                    <select
                                                        className="input-bordered" id="search-field"
                                                        onChange={e => setOrdersStatus(e.target.value)}
                                                    >
                                                        <option value="">Tout</option>
                                                        <option value="CREATED">Créée</option>
                                                        <option value="CONFIRMED">Confirmée</option>
                                                        <option value="PAID">Payée</option>
                                                        <option value="DELIVERING">En cours de livraison</option>
                                                        <option value="DELIVERED">Livrée</option>
                                                        <option value="CANCELED">Annulée</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {loading !== 'orders' && (
                                        <div className="mt-3">
                                            <div className="table-responsive">
                                                <table className="table" style={{borderCollapse: 'separate', borderSpacing: '20px 0px'}}>
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Client</th>
                                                            <th>Date</th>
                                                            <th>Montant TTC</th>
                                                            <th>Vendeur</th>
                                                            <th>Status</th>
                                                            <th className="text-right">Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {orders.map(item => (
                                                            <React.Fragment key={item.id.toString()}>
                                                            <tr>
                                                                <td className="text-success font-weight-bold py-3">#000000{item.id}</td>
                                                                <td>{item.client_name || 'Acheteur inconnu'}</td>
                                                                <td>{item.order_date}</td>
                                                                <td>{Helpers.formatToAmount(item.total_amount)}F</td>
                                                                <td>{item.seller_name}</td>
                                                                {/* <td>{Consts.statusLabel[item.status]}</td> */}
                                                                <td>
                                                                    <div className="dropdown">
                                                                        <BtnLoading
                                                                            role="button" id="dropdownMenuLink" data-toggle="dropdown"
                                                                            aria-haspopup="true" aria-expanded="false"
                                                                            classes={["btn btn-white text-dark btn-xs btn-aut dropdown-toggle"]}
                                                                            style={{minHeight: 'unset', textAlign: 'unset'}}
                                                                            loading={loading === ('changestate-' + item.id)}
                                                                        >
                                                                            {Consts.statusLabel[item.status]} &nbsp;&nbsp;<i className="ti ti-angle-down float-right"></i>
                                                                        </BtnLoading>
                                                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                                            <a className="dropdown-item" href="#" onClick={() => changeOrderState(item, 'CREATED')}>Créée</a>
                                                                            <a className="dropdown-item" href="#" onClick={() => changeOrderState(item, 'CONFIRMED')}>Confirmée</a>
                                                                            <a className="dropdown-item" href="#" onClick={() => changeOrderState(item, 'PAID')}>Payée</a>
                                                                            <a className="dropdown-item" href="#" onClick={() => changeOrderState(item, 'DELIVERING')}>En cours de livraison</a>
                                                                            <a className="dropdown-item" href="#" onClick={() => changeOrderState(item, 'DELIVERED')}>Livrée</a>
                                                                            <a className="dropdown-item" href="#" onClick={() => changeOrderState(item, 'CANCELED')}>Annulée</a>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td className="text-right">
                                                                    <BtnLoading
                                                                        classes={['btn-success btn-xs btn-auto pr-2']}
                                                                        loading={loading === ('printing-invoice-' + item.id)}
                                                                        style={{minHeight: 'unset'}}
                                                                        onClick={() => printInvoice(item.id)}
                                                                    >
                                                                        <i className="ti ti-printer"></i>
                                                                    </BtnLoading>
                                                                </td>
                                                            </tr>
                                                            </React.Fragment>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="mt-3 d-flex">
                                                <button
                                                    className="btn btn-sm btn-dark btn-auto mr-2"
                                                    disabled={page === 1}
                                                    onClick={() => {setPage(page - 1); loadOrders(null, page - 1);}}
                                                >Prec</button>
                                                <button
                                                    className="btn btn-sm btn-dark btn-auto mr-2"
                                                    disabled={page === 1}
                                                    onClick={() => {setPage(1); loadOrders();}
                                                }>0</button>
                                                <button
                                                    className="btn btn-sm btn-dark btn-auto"
                                                    disabled={plength > orders.length}
                                                    onClick={() => {setPage(page + 1); loadOrders(null, page + 1);}
                                                }>Suiv</button>
                                            </div>
                                        </div>
                                    )}
                                    {loading === 'orders' && (
                                        <div className="col-12">
                                            <div className="d-flex justify-content-center align-items-center" style={{height: 400}}>
                                                <div className="p-4 text-center">
                                                    <Loader />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Orders;
