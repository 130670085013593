import Fetcher from "../Utils/Fetcher";
import Helpers from "../Utils/Helpers";
import Consts from './Consts';
import FakeStore from "./FakeStore";

const DashboardService = {

    getDashInfos() {
        return Fetcher.get(`${Consts.baseapi}/dashinfos`, Helpers.authHeader(FakeStore.token));
    },

    getFundInfos() {
        return Fetcher.get(`${Consts.baseapi}/fundinfos`, Helpers.authHeader(FakeStore.token));
    }
};

export default DashboardService;