import React from "react";
import Loader from "../../Components/Loader/Loader";
import ProductService from "../../Services/ProductService";
import DebounceInput from 'react-debounce-input';
import Helpers from "../../Utils/Helpers";
import StocksModal from "./StocksModal";
import StockLinesModal from "./StockLinesModal";

const Stocks = () => {
    const [loading, setLoading] = React.useState(false);
    const [stocks, setStocks] = React.useState([]);
    const [categories, setCategories] = React.useState([]);
    const [searchKeyword, setSearchKeyword] = React.useState('');
    const [selectedCategory, setSelectedCategory] = React.useState(0);
    const [page, setPage] = React.useState(1);
    const [length, setLength] = React.useState(0);
    const [toadd, setToadd] = React.useState(null);
    const [tochange, setToChange] = React.useState(null);

    const closeStockModal = (values) => {
        if (values) {
            values.stock && setStocks(state => state.map((item) => {
                return item.product_id == values.stock.product_id ? {
                    ...item,
                    product_buy_price: values.stock.product_buy_price,
                    product_sell_price: values.stock.product_sell_price,
                    quantity: values.stock.quantity,
                    sold: values.stock.sold
                } : item
            }));
        }

        setToadd(null);
    };

    const handleStockLineChange = (newline) => {
        if (newline) {
            setStocks(state => state.map(item => {
                return parseInt(item.product_id) === parseInt(newline.product_id) ? {...newline} : item
            }));
            
            return;
        }
        
        setToChange(null);
    };

    const doFilter = ({selectedCategory, searchKeyword, page}) => {
        getStocks(Helpers.objToUrlParams({
            cat: selectedCategory || null,
            srk: searchKeyword || null,
            pge: page <= 1 ? null : page
        }, true));
    };

    const getStocks = (filters) => {
        setLoading('stocks');

        ProductService.getStock(filters)
        .then(response => {
            setStocks(response.result);
            setLength(response.plength);
        })
        .catch(err => Helpers.alert.error(err.message))
        .finally(() => setLoading(false));
    };

    const getCategories = () => {
        ProductService.getCategories().then(response => {
            setCategories(response.result);
        });
    };

    React.useEffect(() => {
        doFilter({selectedCategory, searchKeyword, page});
        getCategories();
    }, [searchKeyword, selectedCategory, page]);

    return (
        <>
        <StocksModal
            askClose={(datas) => closeStockModal(datas)}
            toadd={toadd}
        />
        <StockLinesModal
            handleLineChange={handleStockLineChange}
            stock={tochange}
        />
        <div className="Produtcs">
            <div className="card">
                <div className="card-innr">
                    <div className="card-head">
                        {/* <div className="float-right d-flex">
                            <button
                                onClick={() => openStockModal({})}
                                className="badge badge-primary badge-lg"
                            >Ajouter des articles</button>
                        </div> */}
                        {/*  */}
                        <h4 className="card-title">Le stock</h4>
                        <p>Gestion du stock de votre boutique.</p>
                    </div>
                    <div className="card-head d-flex align-items-center justify-content-between border-top border-bottom py-2">
                        <h4 className="font-weight-bold m-0">Filtrer</h4>
                        <div className="d-flex py-1">
                            <DebounceInput
                                minLength={2}
                                debounceTimeout={1000}
                                type="text"
                                className="input-bordered mr-1"
                                placeholder="Rechercher dans les articles"
                                onChange={event => setSearchKeyword(event.target.value)}
                            />
                            <select className="input-bordered"
                                value={selectedCategory}
                                onChange={evt => setSelectedCategory(evt.target.value)}
                            >
                                <option value={0}>Choisir la catégorie</option>
                                {categories.map(item => <option value={item.id} key={item.id.toString()}>{item.name}</option>)}
                            </select>
                        </div>
                    </div>
                    <div>
                    {
                        loading === 'stocks' && (
                            <div className="d-flex justify-content-center align-items-center" style={{height: 300}}>
                                <div className="p-4 text-center">
                                    <Loader />
                                </div>
                            </div>
                        )
                    }
                    {
                        !loading && !stocks.length && (
                            <div className="alert alert-primary mt-4">Aucune information disponible.</div>
                        )
                    }
                    {
                        loading !== 'stocks' && stocks.length ? (
                            <div>
                                <div className="table-responsive">
                                    <table className="table" style={{borderCollapse: 'separate', borderSpacing: '0px 20px'}}>
                                        <thead>
                                            <tr>
                                                <th>Photo</th>
                                                <th>Nom de l'article</th>
                                                <th>P.U achat</th>
                                                <th>P.U vente</th>
                                                <th className="text-center">Qte restante</th>
                                                <th className="px-3 text-right">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            stocks.map(item => {
                                                const remaining = parseFloat(item.quantity||0) - parseFloat(item.sold||0);
                                                return (
                                                    <React.Fragment key={item.product_id.toString()}>
                                                        <tr className="rounded shadow-sm">
                                                            <td className="px-2" width={50}>
                                                                <img
                                                                    src={item.picture ? Helpers.filePath('Products', item.picture) : 'http://via.placeholder.com/100x100'}
                                                                    className="rounded-circle"
                                                                />
                                                            </td>
                                                            <td className="px-2">{item.product_name}</td>
                                                            <td className="px-2">{item.product_buy_price}</td>
                                                            <td className="px-2">{item.product_sell_price}</td>
                                                            <td className="text-center px-2">{remaining}</td>
                                                            <td className="px-3 text-right" width={100}>
                                                                <button
                                                                    style={{minWidth:30, minHeight:30, padding:0}}
                                                                    className="badge badge-outline badge-sm badge-primary rounded ml-1"
                                                                    onClick={() => setToadd(item)}
                                                                >
                                                                    <em className="ti ti-download"></em>
                                                                </button>
                                                                <button
                                                                    style={{minWidth:30, minHeight:30, padding:0}}
                                                                    className="badge badge-outline badge-sm badge-dark rounded ml-1"
                                                                    onClick={() => setToChange(item)}
                                                                >
                                                                    <em className="ti ti-list"></em>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                        </tbody>
                                    </table>
                                </div>
                                <div className="mt-3 border-top pt-3">
                                    {
                                        page > 1 && (
                                            <button
                                                onClick={() => setPage(page - 1)}
                                                disabled={page <= 1}
                                                className="badge badge-primary badge-sm"
                                            >Précédent</button>
                                        )
                                    }
                                    {
                                        length <= stocks.length && (
                                            <button
                                                onClick={() => setPage(page + 1)}
                                                disabled={length > stocks.length}
                                                className="badge badge-primary badge-sm ml-2"
                                            >Suivant</button>
                                        )
                                    }
                                </div>
                            </div>
                        ) : null
                    }
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default Stocks;