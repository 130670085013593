import React from 'react';
import { Modal } from 'bootstrap';
import { Form, Formik, Field } from "formik";
import * as Yup from 'yup';
import Helpers from "../../Utils/Helpers";
import BtnLoading from "../../Components/BtnLoading/BtnLoading";
import FundsService from '../../Services/FundsService';

const ExpenseModal = ({ openmodal, askClose }) => {
    const [loading, setLoading] = React.useState(false);

    const closeModal = (exp) => {
        askClose(exp);
    };

    const doSaveExpense = (values) => {
        setLoading('submit');

        FundsService.saveExpense({...values, date: `${values.date.replace(/-/g, '')}000000`}).then(response => {
            closeModal(response.expense);
            Helpers.alert.success('Dépense bien crée.');
        })
        .catch(err => Helpers.alert.error(err.message))
        .finally(() => setLoading(false));
    };

    React.useEffect(() => {
        let modal = Modal.getOrCreateInstance('#expense-modal');

        openmodal && modal.show();
        !openmodal && modal.hide();
    }, [openmodal]);

    return (
        <>
        <div className="modal fade" id="expense-modal" tabIndex="-1">
            <div className="modal-dialog modal-dialog-md modal-dialog-centered">
                <div className="modal-content">
                    <a href="#" className="modal-close" data-dismiss="modal" aria-label="Close" onClick={() => closeModal()}>
                        <em className="ti ti-close"></em>
                    </a>
                    <div className="popup-body">
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="m-0">Dépense</h2>
                                <p className="text-gray">Créer une demande de dépense.</p>
                                
                                <Formik
                                    enableReinitialize={true}
                                    initialValues={{
                                        label: '',
                                        date: (new Date()).toLocaleDateString('en-CA'),
                                        amount: 0,
                                        description: ''
                                    }}
                                    validationSchema={
                                        Yup.object().shape({
                                            label: Yup.string().required('Attribuez un libellé à la dépense.'),
                                            date: Yup.string().required('Specifiez la date de la dépense.'),
                                            amount: Yup.number().min(1, 'Le montant doit être correct.').required('Entez le montant TTC de la dépense.'),
                                            description: Yup.string()
                                        })
                                    }
                                    onSubmit={(values, x) => {doSaveExpense(values); x.resetForm();}}
                                >
                                    {({ errors, touched, resetForm }) => (
                                        <Form>
                                            <div className="input-item input-with-label">
                                                <label className="input-item-label">Libellé</label>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <Field
                                                            name="label"
                                                            className="input-bordered"
                                                            type="text" placeholder="Libellé"
                                                        />
                                                    </div>
                                                </div>
                                                {touched.label && <span className="text-danger">{errors.label}</span>}
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="input-item input-with-label pb-0">
                                                        <label className="input-item-label">Date de la dépense</label>
                                                        <Field
                                                            name="date"
                                                            className="input-bordered"
                                                            type="date" placeholder="Date de la dépense"
                                                        />
                                                    </div>
                                                    {touched.date && <span className="text-danger">{errors.date}</span>}
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="input-item input-with-label pb-0">
                                                        <label className="input-item-label">Montant de la dépense</label>
                                                        <Field
                                                            name="amount"
                                                            className="input-bordered"
                                                            type="number" placeholder="Montant"
                                                        />
                                                    </div>
                                                    {touched.amount && <span className="text-danger">{errors.amount}</span>}
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-sm-12">
                                                    <div className="input-item input-with-label pb-0">
                                                        <label className="input-item-label">Description</label>
                                                        <Field name="description">
                                                            {({field}) => <textarea {...field} className="input-bordered" placeholder="Ajouter une description" />}
                                                        </Field>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="mt-5">
                                                <button onClick={() => {closeModal(); resetForm();}} type="button" className="btn btn-default btn-dark">Annuler</button>
                                                <BtnLoading
                                                    type="submit"
                                                    loading={loading === 'submit'}
                                                    classes={['btn-primary ml-2']}
                                                >Créer la dépense</BtnLoading>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default ExpenseModal;