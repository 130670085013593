import React from 'react';
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate
} from "react-router-dom";

import PublicLayout from './Components/Layouts/PublicLayout';
import PrivateLayout from './Components/Layouts/PrivateLayout';
import Login from './Pages/Login/Login';
import Signup from './Pages/Signup/Signup';
import Dashboard from './Pages/Dashboard/Dashboard';
import ForgotPassword from './Pages/ForgotPassword/ForgotPassword';
import Profile from './Pages/Profile/Profile';
import Users from './Pages/Users/Users';
import UserLayout from './Components/Layouts/UserLayout';
import Groups from './Pages/Groups/Groups';
import Activities from './Pages/Activities/Activities';
import Categories from './Pages/Categories/Categories';
import ProductLayout from './Components/Layouts/ProductLayout';
import Products from './Pages/Products/Products';
import Stocks from './Pages/Stocks/Stocks';
import Orders from './Pages/Orders/Orders';
import NewOrders from './Pages/Orders/NewOrder';
import Funds from './Pages/Funds/Funds';
import Settings from './Pages/Settings/Settings';


function App() {

	const Logout = () => {

		return (<Navigate to="/" />);
	};

  	return (
		<Router>
			<Routes>
				<Route path="auth" element={<PublicLayout />}>
					<Route path="" element={<Navigate to="/auth/login" />} />
					<Route path="login" element={<Login />} />
					<Route path="register" element={<Signup />}>
						<Route path=":confirm" />
					</Route>
					<Route path="forgot-password" element={<ForgotPassword />} />
				</Route>
				<Route path="/" element={<PrivateLayout />}>
					<Route path="" element={<Dashboard />} />
					<Route path="profile" element={<Profile />} />
					<Route path="settings" element={<Settings />} />
					<Route path="users" element={<UserLayout />}>
						<Route path="" element={<Users />} />
						<Route path="groups" element={<Groups />} />
						<Route path="logs" element={<Activities />} />
					</Route>
					<Route path="products" element={<ProductLayout />}>
						<Route path="" element={<Products />} />
						<Route path="add" element={<Products />} />
						<Route path="categories" element={<Categories />} />
						<Route path="stocks" element={<Stocks />} />
					</Route>
					<Route path="orders" element={<Orders />} />
					<Route path="orders/new" element={<NewOrders />} />
					<Route path="funds" element={<Funds />} />
					<Route path="logout" element={<Logout />} />
				</Route>
			</Routes>
		</Router>
  	);
}

export default App;
