import React from "react";
import { Link, Outlet } from "react-router-dom";

const ProductLayout = () => {

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-lg-3">
                        <div className="card">
                            <div className="card-innr">
                                <div className="card-head">
                                    <h4 className="card-title">Gestion Articles</h4>
                                    <ul className="sidebar-nav mt-3">
                                        <li className="mb-2"><Link to="/products"><em className="ikon bx bxs-purchase-tag"></em> Liste des articles</Link></li>
                                        <li className="mb-2"><Link to="/products/add"><em className="ikon bx bxs-plus-square"></em> Ajouter un article</Link></li>
                                        <li className="mb-2"><Link to="/products/categories"><em className="ikon bx bxs-bookmarks"></em> Catégorie d'articles</Link></li>
                                        <li className="mb-2"><Link to="/products/stocks"><em className="ikon bx bxs-component"></em> Stocks d'articles</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-9">

                        <Outlet />
                        
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProductLayout;