import Fetcher from "../Utils/Fetcher";
import Helpers from "../Utils/Helpers";
import Consts from './Consts';
import FakeStore from "./FakeStore";

const ProductService = {

    getCategories() {
        return Fetcher.get(`${Consts.baseapi}/categories`, Helpers.authHeader(FakeStore.token));
    },

    saveCategory(datas) {
        const func = datas.id ? Fetcher.put : Fetcher.post;
        const suffix = datas.id ? '/' + datas.id : '';
        return func(`${Consts.baseapi}/categories${suffix}`, datas, Helpers.authHeader(FakeStore.token));
    },

    toggleCategory(id) {
        return Fetcher.put(`${Consts.baseapi}/categories/${id}/toggle`, {}, Helpers.authHeader(FakeStore.token));
    },

    deleteCategory(id) {
        return Fetcher.delete(`${Consts.baseapi}/categories/${id}`, Helpers.authHeader(FakeStore.token));
    },

    getProducts(filters='') {
        return Fetcher.get(`${Consts.baseapi}/products?${filters}`, Helpers.authHeader(FakeStore.token));
    },

    saveProduct(datas) {
        const func = datas.id ? Fetcher.put : Fetcher.post;
        const suffix = datas.id ? '/' + datas.id : '';
        return func(`${Consts.baseapi}/products${suffix}`, datas, Helpers.authHeader(FakeStore.token));
    },

    toggleProduct(id) {
        return Fetcher.put(`${Consts.baseapi}/products/${id}/toggle`, {}, Helpers.authHeader(FakeStore.token));
    },

    deleteProduct(id) {
        return Fetcher.delete(`${Consts.baseapi}/products/${id}`, Helpers.authHeader(FakeStore.token));
    },

    getStock(filters='') {
        return Fetcher.get(`${Consts.baseapi}/stock?${filters}`, Helpers.authHeader(FakeStore.token));
    },

    saveStock(datas) {
        return Fetcher.post(`${Consts.baseapi}/stock`, datas, Helpers.authHeader(FakeStore.token));
    },

    getStockLines(product_id) {
        return Fetcher.get(`${Consts.baseapi}/stock/lines/${product_id}`, Helpers.authHeader(FakeStore.token));
    },

    activateStockLines(product_id, stock_id) {
        return Fetcher.put(`${Consts.baseapi}/stock/lines`, {product_id, stock_id}, Helpers.authHeader(FakeStore.token));
    }

};

export default ProductService;