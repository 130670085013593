import React from 'react';
import { Modal } from 'bootstrap';
import { Form, Formik, Field } from "formik";
import * as Yup from 'yup';
import Helpers from "../../Utils/Helpers";
import BtnLoading from "../../Components/BtnLoading/BtnLoading";
import UserService from '../../Services/UserService';

const ClientModal = ({ toadd, askClose }) => {
    const [loading, setLoading] = React.useState(false);

    const doSaveClient = (values) => {
        setLoading('submit');

        UserService.saveUser({...values, password: values.email, role: 'CLIENT'}).then(response => {
            closeModal(response.user);
        })
        .catch(err => Helpers.alert.error(err.message))
        .finally(() => setLoading(false));
    };

    const findClient = (email) => {
        setLoading('find-client');

        UserService.getUserBy('email', email, 'CLIENT').then(response => {
            if (!response.user) {
                Helpers.alert.error('Cet email ne correspond à aucun client.');
                return;
            }

            closeModal(response.user);
        })
        .catch(err => {
            Helpers.alert.error(err.message);
        })
        .finally(() => setLoading(false));
    };

    const closeModal = (cli) => {
        if (loading) return;
        askClose(cli);
    };

    React.useEffect(() => {
        const modal = Modal.getOrCreateInstance('#client-modal');

        toadd && modal.show();
        !toadd && modal.hide();
    }, [toadd]);

    return (
        <div className="modal fade" id="client-modal" tabIndex="-1">
            <div className="modal-dialog modal-dialog-md modal-dialog-centered">
                <div className="modal-content">
                    <a href="#" className="modal-close" data-dismiss="modal" aria-label="Close" onClick={() => closeModal()}>
                        <em className="ti ti-close"></em>
                    </a>
                    <div className="popup-body">
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="m-0">Client</h2>
                                <p className="text-gray">Ajouter un client à la commande.</p>
                                
                                <Formik
                                    enableReinitialize={true}
                                    initialValues={{
                                        name: toadd?.name || '',
                                        email: toadd?.email || '',
                                        phone: toadd?.phone || ''
                                    }}
                                    validationSchema={
                                        Yup.object().shape({
                                            name: Yup.string().required('Le nom du client est requis.'),
                                            email: Yup.string().email('Entrez un email correct.').required('Le mail du client est requis.'),
                                            phone: Yup.string()
                                        })
                                    }
                                    onSubmit={values => doSaveClient(values)}
                                >
                                    {({ errors, touched, values }) => (
                                        <Form>
                                            <div className="input-item input-with-label">
                                                <label className="input-item-label">Email</label>
                                                <div className="row">
                                                    <div className="col-10">
                                                        <Field
                                                            name="email"
                                                            className="input-bordered"
                                                            type="text" placeholder="Email"
                                                        />
                                                    </div>
                                                    <div className="col-2">
                                                        <BtnLoading
                                                            className={["btn btn-primary btn-block"]}
                                                            onClick={() => findClient(values.email)}
                                                            style={{minWidth: 'unset'}}
                                                            type="button"
                                                            loading={loading === 'find-client'}
                                                        >
                                                            <i className="ti ti-check"></i>
                                                        </BtnLoading>
                                                    </div>
                                                </div>
                                                {errors.email && <span className="text-danger">{errors.email}</span>}
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="input-item input-with-label pb-0">
                                                        <label className="input-item-label">Nom du client</label>
                                                        <Field
                                                            name="name"
                                                            className="input-bordered"
                                                            type="text" placeholder="Nom du client"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="input-item input-with-label pb-0">
                                                        <label className="input-item-label">Téléphone</label>
                                                        <Field
                                                            name="phone"
                                                            className="input-bordered"
                                                            type="text" placeholder="Télépohne"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {errors.name && <span className="text-danger">{errors.name}</span>}

                                            <div className="mt-5">
                                                <button onClick={() => closeModal()} type="button" className="btn btn-default btn-dark">Annuler</button>
                                                <BtnLoading
                                                    type="submit"
                                                    loading={loading === 'submit'}
                                                    classes={['btn-primary ml-2']}
                                                    disabled={loading === 'find-client'}
                                                >Créer et utiliser</BtnLoading>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ClientModal;