export const LOGIN_ACTION = 'LOGIN_ACTION';
export const UPDATE_ACTION = 'UPDATE_ACTION';
export const LOGOUT_ACTION = 'LOGOUT_ACTION';

export function userLogin(payload) {
    return {
        type: LOGIN_ACTION,
        payload
    };
}

export function userUpdate(payload) {
    return {
        type: UPDATE_ACTION,
        payload
    }
}

export function userLogout() {
    return {
        type: LOGOUT_ACTION
    }
}