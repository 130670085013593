import React from 'react';
import { useCountUp } from 'react-countup';
import BtnLoading from '../../Components/BtnLoading/BtnLoading';
import Loader from '../../Components/Loader/Loader';
import DashboardService from '../../Services/DashboardService';
import FundsService from '../../Services/FundsService';
import OrdersService from '../../Services/OrdersService';
import Helpers from '../../Utils/Helpers';
import ExpenseModal from './ExpenseModal';

const Funds = () => {
    const expenseStatus = {
        '-1': 'Rejetée',
        '0': 'En attente',
        '1': 'Validée'
    };

    const [loading, setLoading] = React.useState(false);
    const [expenses, setExpenses] = React.useState([]);
    const [orders, setOrders] = React.useState([]);
    const [statInfos, setStatInfos] = React.useState({collected: 0, spent: 0, benefits: 0});
    const [openExpenseModal, setOpenExpenseModal] = React.useState(false);

    const benefitCount = useCountUp({ ref: 'benefitCount', start: 0, delay: 1, formattingFn: Helpers.formatToAmount });
    const collectedCount = useCountUp({ ref: 'collectedCount', start: 0, delay: 1, formattingFn: Helpers.formatToAmount });
    const spentCount = useCountUp({ ref: 'spentCount', start: 0, delay: 1, formattingFn: Helpers.formatToAmount });
    

    const closeExpenseModal = (expense) => {
        expense && setExpenses(state => [expense, ...state]);
        setOpenExpenseModal(false);
    };


    const loadDefaults = () => {
        setLoading('loading-defaults');

        Promise.all([
            FundsService.getExpenses(),
            OrdersService.getOrders(null, Helpers.objToUrlParams({status: 'PAID'})),
            DashboardService.getFundInfos()
        ]).then(
            ([_expenses, _orders, _fundInfos]) => {
                setExpenses(_expenses.result);
                setOrders(_orders.result);
                setStatInfos({
                    benefits: _fundInfos.benefits,
                    collected: _fundInfos.collected,
                    spent: _fundInfos.spent
                });

                setTimeout(() => {
                    benefitCount.update(_fundInfos.benefits);
                    collectedCount.update(_fundInfos.collected);
                    spentCount.update(_fundInfos.spent);
                }, 1000);
            }
        )
        .catch(err => Helpers.alert.error(err.message))
        .finally(() => setLoading(false));
    };


    const getOrders = (page) => {
        setLoading('get-orders');

        OrdersService.getOrders(null, Helpers.objToUrlParams({status: 'PAID', page})).then(({result}) => {
            setOrders(state => ([...state, ...result]));
        })
        .catch(err => Helpers.alert.error(err.message))
        .finally(() => setLoading(false));
    };


    const getExpenses = (page) => {
        setLoading('get-expenses');

        FundsService.getExpenses(null, Helpers.objToUrlParams({ page })).then(({result}) => {
            setOrders(result);    
        })
        .catch(err => Helpers.alert.error(err.message))
        .finally(() => setLoading(false));
    };


    const doValidate = (expense, reject=false) => {
        Helpers.alert.confirm('Vous allez '+ ( reject ? 'rejeter' : 'valider' ) +' cette dépense. Souhaitez vous continuer ?').then(() => {
            setLoading((reject ? 'reject' : 'validate') + '-' + expense.id);

            const func = reject ? FundsService.rejectExpense : FundsService.activateExpense;

            func(expense.id).then(({ newstate }) => {
                setExpenses(state => state.map(item => {
                    return item.id === expense.id ? {...item, active: newstate} : item
                }));

                // update expense stats
                !reject && spentCount.update(parseFloat(statInfos.spent) + parseFloat(expense.amount));
            })
            .catch(err => Helpers.alert.error(err.message))
            .finally(() => setLoading(false));
        });
    };


    React.useEffect(() => {
        loadDefaults();
    }, []);


    return (
        <>
        <ExpenseModal
            openmodal={openExpenseModal}
            askClose={closeExpenseModal}
        />

        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-innr">
                            <div className="token-overvie">
                                <div className="row">
                                    <div className="col-md-4 col-sm-6 text-center border-right">
                                        <div className="token-bonus token-bonus-sale py-0">
                                            <span className="token-overview-title">Dans la caisse (F cfa)</span>
                                            <span className="token-overview-value bonus-on-sale fs-3">
                                                <b id="collectedCount" />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-6 text-center border-right">
                                        <div className="token-bonus token-bonus-amount py-0">
                                            <span className="token-overview-title">Dépenses effectuées (F cfa)</span>
                                            <span className="token-overview-value bonus-on-amount fs-3">
                                                <b id="spentCount" />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-md-4 text-center">
                                        <div className="token-total py-0">
                                            <span className="token-overview-title font-bold">Bénéfices ventes (F cfa)</span>
                                            <span className="token-overview-value token-total-amount text-primary fs-3">
                                                <b id="benefitCount" />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {loading !== 'loading-defaults' && (
                <>
                <div className="row">
                    <div className="col-md-5">
                        <div className="card">
                            <div className="card-innr">
                                <h2 className="fs-4 font-weight-bold">Entrées</h2>
                                <div
                                    style={{height:400}}
                                    className="table-responsive border-top pt-3 mt-4"
                                    onScroll={evt => {
                                        if (evt.target.offsetHeight + evt.target.scrollTop >= evt.target.scrollHeight) {
                                            const nextPage = parseInt(orders.length / 10);
                                            nextPage > 0 && getOrders(parseInt(orders.length / 10) + 1);
                                        }
                                    }}
                                >
                                    <table className="table" style={{borderCollapse:'separate', borderSpacing:'1px 0'}}>
                                        <thead>
                                            <tr>
                                                <th role="th">Intitulé</th>
                                                <th role="th">Date & heure</th>
                                                <th role="th" className="text-right" width={100}>Montant</th>
                                                <th role="th" className="text-center">Taxe</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            orders.map(item => (
                                                <React.Fragment key={item.id}>
                                                <tr>
                                                    <td>Commande #{item.id}</td>
                                                    <td>{Helpers.parseDate(item.order_date).format('DD/MM/YYYY')}</td>
                                                    <td className="text-right">{Helpers.formatToAmount(item.total_amount)}Fcfa</td>
                                                    <td className="text-center text-danger">-{item.vat}%</td>
                                                </tr>
                                                </React.Fragment>
                                            ))
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-7">
                        <div className="card">
                            <div className="card-innr">
                                <div className="">
                                    <button
                                        className="btn btn-primary btn-sm btn-auto float-right"
                                        onClick={() => setOpenExpenseModal((new Date()).getTime())}
                                    >Créer une dépense</button>
                                    <h2 className="fs-4 font-weight-bold">Dépenses</h2>
                                </div>
                                <div
                                    style={{height:400}}
                                    className="table-responsive border-top pt-3 mt-4"
                                    onScroll={evt => {
                                        if (evt.target.offsetHeight + evt.target.scrollTop >= evt.target.scrollHeight) {
                                            const nextPage = parseInt(expenses.length / 10);
                                            nextPage > 0 && getExpenses(parseInt(expenses.length / 10) + 1);
                                        }
                                    }}
                                >
                                    {
                                        !!expenses.length && (  
                                            <table className="table" style={{borderCollapse:'separate', borderSpacing:'5px 0'}}>
                                                <thead>
                                                    <tr>
                                                        <th role="th">Intitulé</th>
                                                        <th role="th">Date & heure</th>
                                                        <th role="th" className="text-right" width={100}>Montant</th>
                                                        <th role="th" className="text-right" width={70}></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    expenses.map((item) => (
                                                        <React.Fragment key={item.id.toString()}>
                                                        <tr>
                                                            <td>
                                                                <i className="ti ti-angle-right"></i>
                                                                {item.label}
                                                            </td>
                                                            <td>{Helpers.parseDate(item.date).format('DD/MM/YYYY')}</td>
                                                            <td className="text-right">{Helpers.formatToAmount(item.amount)}Fcfa</td>
                                                            <td className="text-right" width={50}>
                                                            {
                                                                parseInt(item.active) === 0 ? (
                                                                    <>
                                                                    <BtnLoading
                                                                        class={['btn btn-xs btn-success btn-auto pl-1 mr-1']}
                                                                        title="Valider"
                                                                        loading={loading === 'validate-' + item.id}
                                                                        onClick={() => doValidate(item)}
                                                                    >
                                                                        <i className="ti ti-check"></i>
                                                                    </BtnLoading>
                                                                    <BtnLoading
                                                                        className={['btn btn-xs btn-danger btn-auto pl-1']}
                                                                        title="Rejeter"
                                                                        loading={loading === 'reject-' + item.id}
                                                                        onClick={() => doValidate(item, true)}
                                                                    >
                                                                        <i className="ti ti-close"></i>
                                                                    </BtnLoading>
                                                                    </>
                                                                ) : (
                                                                    <b className={'text-' + (parseInt(item.active) === -1 ? 'danger' : 'success')}>
                                                                        {expenseStatus[item.active.toString()]}
                                                                    </b>
                                                                )
                                                            }
                                                            </td>
                                                        </tr>
                                                        </React.Fragment>
                                                    ))
                                                }
                                                </tbody>
                                            </table>
                                        )
                                    }
                                    {
                                        !expenses.length && (
                                            <div className="alert alert-info py-3">Vous n'avez pas encore créé de dépense.</div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </>
            )}
            {loading === 'loading-defaults' && (
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-innr d-flex align-items-center justify-content-center" style={{height:500}}>
                                <Loader />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
        </>
    );
};

export default Funds;