import { useFormik } from 'formik';
import React from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import BtnLoading from '../../Components/BtnLoading/BtnLoading';
import FlatAlert from '../../Components/FlatAlert/FlatAlert';
import AuthService from '../../Services/AuthService';

const ForgotPassword = () => {
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [steps, setSteps] = React.useState(1);

    const form1 = useFormik({
        initialValues: { email: '' },
        validationSchema: Yup.object().shape({ email: Yup.string().required('Entrez votre email.').email('Veuillez entrer un email correct.') }),
        onSubmit(values) {
            doSendRestoreEmail(values);
        }
    });

    const form2 = useFormik({
        initialValues: {
            code: '',
            newpassword: '',
            confirm_newpassword: ''
        },
        validationSchema: Yup.object().shape({
            code: Yup.string().required('Entrez le code reçu par email.').min(4, 'Veuillez entrer le code reçu par email.'),
            newpassword: Yup.string().required('Choisissez un mot de passe.').min(6, 'Choisissez un mot de passe plus long.'),
            confirm_newpassword: Yup.string().oneOf([Yup.ref('newpassword'), null], 'Les mots de passe ne correspondent pas.')
        }),
        onSubmit(values) {
            doRestorePassword(values);
        }
    });

    const doSendRestoreEmail = (values) => {
        setError(null);
        setLoading(true);

        AuthService.forgotPassword(values).then(() => {
            setSteps(2);
        })
        .catch(err => {
            setError(err.message);
        })
        .finally(() => setLoading(false));
    };

    const doRestorePassword = (values) => {
        setError(null);
        setLoading(true);

        AuthService.restorePassword(values).then(() => {
            setSteps(99);
        })
        .catch(err => {
            setError(err.message);
        })
        .finally(() => setLoading(false));
    };

    return (
        <>
        {
            steps !== 99 && (
                <h2 className="page-ath-heading">
                    Mot de passe oublié ?
                    <small>Pas de panique ! Suivez le processus pour restaurer votre mot de passe.</small>
                </h2>
            )
        }

        {
            steps === 1 && (
                <form onSubmit={form1.handleSubmit} className="form-validate validate-modern">
                    {error && <FlatAlert type="danger" message={error} />}

                    <div className="input-item">
                        <input
                            name="email"
                            type="email"
                            placeholder="Votre Email"
                            className="input-bordered"
                            onChange={form1.handleChange}
                            onBlur={form1.handleBlur}
                            value={form1.values.email}
                        />
                        {form1.touched.email && form1.errors.email && 
                            <label id="full-name-error" className="error">{form1.errors.email}</label>}
                    </div>
                    <BtnLoading
                        type="submit"
                        classes={['btn-primary', 'btn-block']}
                        loading={steps === 1 && loading}
                    >Reinitialiser</BtnLoading>
                </form>
            )
        }

        {
            steps === 2 && (
                <form onSubmit={form2.handleSubmit} className="form-validate validate-modern">
                    {!error && <FlatAlert type="info" message="Entrez le code que vous avez reçu par email puis votre nouveau mot de passe." />}
                    {error && <FlatAlert type="danger" message={error} />}

                    <div className="input-item">
                        <input
                            name="code"
                            type="text"
                            placeholder="Code reçu"
                            className="input-bordered"
                            onChange={form2.handleChange}
                            onBlur={form2.handleBlur}
                            value={form2.values.code}
                        />
                        {form2.touched.code && form2.errors.code && 
                            <label id="full-name-error" className="error">{form2.errors.code}</label>}
                    </div>
                    <div className="input-item">
                        <input
                            name="newpassword"
                            type="password"
                            placeholder="Nouveau mot de passe"
                            className="input-bordered"
                            onChange={form2.handleChange}
                            onBlur={form2.handleBlur}
                            value={form2.values.newpassword}
                        />
                        {form2.touched.newpassword && form2.errors.newpassword && 
                            <label id="full-name-error" className="error">{form2.errors.newpassword}</label>}
                    </div>
                    <div className="input-item">
                        <input
                            name="confirm_newpassword"
                            type="password"
                            placeholder="Confirmer le mot de passe"
                            className="input-bordered"
                            onChange={form2.handleChange}
                            onBlur={form2.handleBlur}
                            value={form2.values.confirm_password}
                        />
                        {form2.touched.confirm_newpassword && form2.errors.confirm_newpassword && 
                            <label id="full-name-error" className="error">{form2.errors.confirm_newpassword}</label>}
                    </div>
                    <div className="d-flex">
                        <button
                            className="btn btn-outline btn-dark"
                            onClick={() => setSteps(1) }
                        >Retour</button>
                        <BtnLoading
                            type="submit"
                            classes={['btn-primary', 'btn-block', 'ml-2']}
                            loading={steps === 2 && loading}
                        >Reinitialiser</BtnLoading>
                    </div>
                </form>
            )
        }

        {
            steps === 99 && (
                <>
                <div class="status status-thank p-0">
                    <div class="status-icon m-0">
                        <em class="ti ti-check"></em>
                    </div>
                </div>
                <h2 className="page-ath-heading mt-4">
                    Mot de passe réinitialisé !
                    <small>Votre mot de passe a été réinitialisé avec succès. Connectez-vous à présent.</small>
                </h2>
                </>
            )
        }

            <div className="gaps-2x"></div>
            <div className="gaps-2x"></div>
            <div className="form-note">
                <i className="ti ti-arrow-left"></i> <Link to="/auth"><strong>Revenir à la connexion</strong></Link>
            </div>
        </>
    );
};

export default ForgotPassword;