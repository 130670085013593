import React from 'react';
import { Modal } from 'bootstrap';
import Loader from '../../Components/Loader/Loader';
import ProductService from "../../Services/ProductService";
import Helpers from "../../Utils/Helpers";
import BtnLoading from "../../Components/BtnLoading/BtnLoading";

const StockLinesModal = ({stock, handleLineChange}) => {
    const [stockLines, setStockLines] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    const loadStockLines = (product_id) => {
        setLoading('stock-lines');

        ProductService.getStockLines(product_id).then(response => {
            setStockLines(response.lines);
        })
        .catch(err => Helpers.alert.error(err.message))
        .finally(() => setLoading(false));
    };

    const activateStockLine = (line) => {
        setLoading('activate-' + line.id);

        ProductService.activateStockLines(line.product_id, line.id).then(response => {
            // give active to who deserve
            setStockLines(state => state.map(item => {
                return {...item, active: parseInt(item.id) === parseInt(line.id) ? 1 : 0}
            }));

            // notify down
            handleLineChange(response.newline);
        })
        .catch(err => Helpers.alert.error(err.nessage))
        .finally(() => setLoading(false));
    };
    
    React.useEffect(() => {
        const modal = Modal.getOrCreateInstance('#stock-lines-modal', {backdrop: 'static', keyboard: false});

        stock && loadStockLines(stock.product_id);

        stock && modal.show();
        !stock && modal.hide();
    }, [stock]);

    return (
        <>
        <div className="modal fade" id="stock-lines-modal" tabIndex="-1">
            <div className="modal-dialog modal-dialog-lg modal-dialog-centered">
                <div className="modal-content">
                    <a href="#!" className="modal-close" data-dismiss="modal" aria-label="Close" onClick={() => handleLineChange()}>
                        <em className="ti ti-close"></em>
                    </a>
                    <div className="popup-body">
                        {!!stockLines.length && loading !== 'stock-lines' && (
                            <>
                            <div className="row">
                                <div className="col-md-12">
                                    <h2 className="m-0"> Liste des stocks</h2>
                                    <p className="text-gray">Liste des stocks disponibles pour un article.</p>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-md-12">
                                    <div className="table-responsive">
                                        <table className="table" style={{borderCollapse: 'separate', borderSpacing: '10px 0px'}}>
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Nom de l'article</th>
                                                    <th>P.U achat</th>
                                                    <th>P.U vente</th>
                                                    <th>Date d'ajout</th>
                                                    <th className="text-center">Qte restante</th>
                                                    <th className="px-3 text-right">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {stockLines.map((item, idx) => {
                                                    const remaining = parseFloat(item.quantity||0) - parseFloat(item.sold||0);
                                                    return (
                                                        <tr key={(idx+1).toString()}>
                                                            <td>{idx + 1}</td>
                                                            <td>{item.product_name}</td>
                                                            <td>{item.product_buy_price}</td>
                                                            <td>{item.product_sell_price}</td>
                                                            <td>{Helpers.parseDate(item.added_at.replace(/[\-\s]/g, '').replace(':', '')).format('DD/MM/YYYY')}</td>
                                                            <td className="text-center">{remaining}</td>
                                                            <td className="text-right pr-3">
                                                                <BtnLoading
                                                                    style={{width: 70}}
                                                                    className={["btn btn-success btn-xs btn-auto pr-2"]}
                                                                    disabled={parseInt(item.active) === 1 || remaining === 0}
                                                                    loading={loading === 'activate-' + item.id}
                                                                    onClick={() => activateStockLine(item)}
                                                                >
                                                                    {parseInt(item.active) ? 'Activé' : (remaining === 0 ? 'Epuisé' : 'Activer')}
                                                                </BtnLoading>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            </>
                        )}
                        {!stockLines.length && (
                            <div className="d-flex align-items-center justify-content-center" style={{height:300}}>
                                <Loader />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default StockLinesModal;