import React from "react";
import PrivateTopBar from '../PageParts/PrivateTopBar';
import PrivateNavBar from '../PageParts/PrivateNavBar';
import PrivateFooter from '../PageParts/PrivateFooter';
import { Outlet } from "react-router-dom";
import PrivateGuard from "../Guards/PrivateGuard";

const PrivateLayout = () => {

    return (
        <>
        <PrivateGuard>

            <div className="page-user">
                <div className="topbar-wrap">
                    <PrivateTopBar />
                    <PrivateNavBar />
                </div>
                
                <div className="page-content">
                    <Outlet />
                </div>

                <PrivateFooter /> 
            </div>
            
        </PrivateGuard>
        </>
    );
};

export default PrivateLayout;